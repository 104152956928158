import { AuthDto, EmailDto, PasswordDto, LoginDto, CodeDto, ChangePasswordDto, AccessStateDto } from './types'
import { ApiKeyDto, Success, TokenDto } from '../../types/common'
import { Api } from '../Api'

class AuthApi extends Api {
  private constructor() {
    super({
      baseURL: `${process.env.REACT_APP_BASE_AUTH}/api/v1/auth/`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
  private static instance: AuthApi

  static getInstance(): AuthApi {
    if (AuthApi.instance) {
      return this.instance
    }
    return this.instance = new AuthApi()
  }

  public login(data: LoginDto): Promise<AuthDto>{
    return this.post<AuthDto>(`user/login`, data)
  }

  public logout(): Promise<Success>{
    return this.post<Success>('user/logout')
  }

  public register(data: EmailDto & PasswordDto): Promise<AuthDto>{
    return this.post<AuthDto>(`user/register`, data)
  }

  public resendActivationEmail(data: EmailDto): Promise<Success> {
    return this.patch<Success>(`user/email/verify/resend`, { email: data.email })
  }
  
  public activateUser(data: EmailDto & CodeDto): Promise<AuthDto> {
    return this.patch<AuthDto>('user/email/verify', { 
      email: data.email,
      code: data.code 
    })
  }

  public sendResetPasswordRequest(data: EmailDto): Promise<Success> {
    return this.post<Success>('user/password/reset/start', { email: data.email })
  }

  public checkResetPasswordToken(data: CodeDto & EmailDto): Promise<TokenDto> {
    return this.post<TokenDto>('user/password/reset/check', {
      email: data.email,
      code: data.code
    })
  }

  public finishPasswordReset(data: PasswordDto): Promise<Success> {
    return this.patch<Success>('user/password/reset/finish', { password: data.password })
  }

  public changePassword(data: ChangePasswordDto): Promise<Success> {
    return this.patch<Success>('user/password/change', data)
  }

  public generateApiKey(): Promise<ApiKeyDto> {
    return this.post<ApiKeyDto>('api-key/generate')
  }
}

export default AuthApi