import { EmailDto } from '../../api/auth/types'
import AuthApi from '../../api/auth/AuthApi'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { showErrorToast } from '../../utils/error'

const authApi = AuthApi.getInstance()

export default function useResendActivationEmail() {
  const texts = useTranslation().t
  const mutation = useMutation((data: EmailDto) => authApi.resendActivationEmail(data), {
    onSuccess: () => {
      toast.success(texts('successes:activation_mail_resent'))
    },
    onError: (error) => {
      showErrorToast(error, texts)
    },
  })
  return mutation
}