import { Fade, IconButton, Popper, Typography } from '@mui/material'
import { useState, useRef } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useTranslation } from 'react-i18next'

type Props = {
  copiedText: string
}

function CopyButton(props: Props) {
  const texts = useTranslation().t
  const ref = useRef<HTMLButtonElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)
  function handleCopyClick(event: React.MouseEvent<HTMLButtonElement>) {
    setOpen(true)
    setTimeout(() => setOpen(false), 2000)
  }

  return (
    <CopyToClipboard text={props.copiedText}>
      <IconButton onClick={handleCopyClick} ref={ref}>
        <ContentCopyIcon/>
        <Popper
          transition
          open={open}
          anchorEl={ref.current}
        >
          {({ TransitionProps }) => (
            <Fade
              {...TransitionProps}
              in={open}
              timeout={500}
            >
              <Typography
                fontFamily={'Roboto'}
                fontSize={'10px'}
                color={'white'}
                sx={{ 
                  borderRadius: '4px',
                  padding: '4px 8px 4px 8px',
                  backgroundColor: '#616161',
                  zIndex: 10
                }}>
                {texts('common:copied')}
              </Typography>
            </Fade>
          )}
        </Popper>
      </IconButton>
    </CopyToClipboard>
  )
}

export default CopyButton