import { LoadingButton } from '@mui/lab'
import { Grid, Paper, Typography, useTheme } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useTranslation } from 'react-i18next'

type Props = {
  title: string
  imageSrc: string
  description: string
  isLoading?: boolean
  onButtonClick: () => void
}

function AccountTile(props: Props) {
  const theme = useTheme()
  const texts = useTranslation().t

  return (
    <Paper
      elevation={4}
      sx={{
        backgroundColor: '#fff',
        borderRadius: '20px',
        [theme.breakpoints.up('md')]: {
          width: '560px',
          minHeight: '246px'
        },
        [theme.breakpoints.down('md')]: {
          width: '350px',
          minHeight: '174px'
        },
        [theme.breakpoints.down('sm')]: {
          width: 'auto',
          minHeight: '152px',
          maxWidth: '320px'
        }
      }}
    >
      <Grid
        container
        gap={{ xs:'6px', sm: '20px' }}
        direction={'column'}
        sx={{
          backgroundImage: `url(${props.imageSrc})`,
          backgroundRepeat: 'no-repeat',
          [theme.breakpoints.up('md')]: {
            backgroundSize: '64px',
            backgroundPositionX: '30px',
            backgroundPositionY: '30px',
            padding: '30px 30px 30px 114px'
          },
          [theme.breakpoints.down('md')]: {
            backgroundSize: '32px',
            backgroundPositionX: '20px',
            backgroundPositionY: '20px',
            padding: '20px 20px 20px 64px'
          },
          [theme.breakpoints.down('sm')]: {
            backgroundSize: '24px',
            backgroundPositionX: '20px',
            backgroundPositionY: '20px',
            padding: '20px 20px 20px 52px'
          },
        }}
      >
        <Typography
          fontFamily={'Catamaran Roman Bold'}
          fontSize={'18px'}
          lineHeight={'24px'}
        >
          {props.title}
        </Typography>
        <Typography
          fontFamily={'Roboto'}
          fontSize={{ xs: '14px', md: '16px' }}
          lineHeight={{ xs: '18px', md: '20px' }}
          minHeight={{ xs: '56px', sm: '38px', md: '69px' }}
        >
          {props.description}
        </Typography>

        <LoadingButton
          onClick={props.onButtonClick}
          loading={props.isLoading}
          variant={'contained'}
          endIcon={
            <ArrowForwardIcon
              sx={{
                [theme.breakpoints.up('md')]: {
                  width: '24px',
                  height: '24px'
                },
                [theme.breakpoints.down('md')]: {
                  width: '18px',
                  height: '18px'
                },
                [theme.breakpoints.down('sm')]: {
                  width: '14px',
                  height: '14px'
                }
              }}
            />
          }
          sx={{
            alignSelf: 'flex-end',
            textTransform: 'none',
            boxShadow: 0,
            fontFamily: 'Catamaran Roman Bold',
            [theme.breakpoints.up('md')]: {
              width: '200px',
              height: '56px',
              borderRadius: '50px',
              fontSize: '24px'
            },
            [theme.breakpoints.down('md')]: {
              width: '166px',
              height: '40px',
              borderRadius: '20px',
              fontSize: '16px'
            },
            [theme.breakpoints.down('sm')]: {
              width: '118px',
              height: '30px',
              borderRadius: '50px',
              fontSize: '14px'
            }
          }}
        >
          {texts('accounts:tile_button_text')}
        </LoadingButton>
      </Grid>
    </Paper>
  )
}

export default AccountTile