import AuthLayout from '../../components/layouts/AuthLayout'
import { Box, Grid, Link, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Form, Formik, FormikHelpers } from 'formik'
import TextField from '../../components/customMui/TextFields'
import PasswordField from '../../components/customMui/PasswordField'
import { SignUpDto } from '../../api/auth/types'
import { signUpValidation } from '../../utils/validations/auth'
import Checkbox from '../../components/customMui/Checkbox'
import { LoadingButton } from '@mui/lab'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useSignUp from '../../hooks/auth/useSignUp'
import { Helmet } from 'react-helmet'

function SignUp() {
  const texts = useTranslation().t
  const theme = useTheme()
  const navigate = useNavigate()
  const mutation = useSignUp()
  const [searchParams, setSearchParams] = useSearchParams()

  function handleSubmit(data: SignUpDto, formikHelpers: FormikHelpers<SignUpDto>) {
    mutation.mutate({
      email: data.email,
      password: data.password
    }, {
      onSuccess: () => {
        const subscribeNow = searchParams.get('subscribe')

        navigate(`/activation${subscribeNow ? '?subscribe=true' : ''}`, { 
          state: {
            email: data.email
          }
        })
      },
      onError: () => {
        formikHelpers.setValues({
          email: data.email,
          password: '',
          policyAgreed: false
        })
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>{texts('pageTitles:sign_up')}</title>
      </Helmet>
      <AuthLayout>
        <Formik<SignUpDto>
          initialValues={{
            email: '',
            password: '',
            policyAgreed: false
          }}
          onSubmit={handleSubmit}
          validationSchema={signUpValidation(texts)}
          validateOnChange={true}
          validateOnBlur={true}
          >
          <Form>
            <Grid
              gap={{ xs: 2, sm: 3, md: 4 }}
              container
              direction={'column'}
              alignItems={'center'}
              width={'100%'}
            >
              <Typography variant={'subtitle1'}>{texts('auth:create_account')}</Typography>
              
              <TextField
                name='email'
                type='email'
                label={texts('auth:email_address')}
              />
              <PasswordField
                name='password'
                label={texts('auth:password')} 
              />
              
              <Checkbox 
                name='policyAgreed'
                sx={{
                  [theme.breakpoints.up('md')]: {
                    marginTop: -1.5
                  },
                  [theme.breakpoints.up('sm')]: {
                    marginTop: -1
                  },
                  [theme.breakpoints.down('sm')]: {
                    marginTop: -0.5
                  }
                }}
                label={
                  <Typography 
                    marginTop={{ xs: '10px', md: '8px' }}
                    sx={{
                      fontFamily: 'Roboto',
                      [theme.breakpoints.up('md')]: {
                        lineHeight: '20px',
                        fontSize: '16px'
                      },
                      [theme.breakpoints.down('md')]: {
                        lineHeight: '18px',
                        fontSize: '14px'
                      }
                    }}
                  >
                    {texts('auth:agreement_description')}
                    <Link 
                      href={process.env.REACT_APP_PRIVACY_POLICY_URL!}
                      underline={'none'} 
                    >
                      {texts('common:privacy_policy')}
                    </Link>
                      {texts('common:semicolon')}
                    <Link
                      href={process.env.REACT_APP_TERMS_URL}
                      underline={'none'}
                    >
                      {texts('common:terms')}
                    </Link>
                    {texts('common:and')}
                    <Link
                      href={process.env.REACT_APP_REFUND_POLICY_URL}
                      underline={'none'}
                    >
                      {texts('common:refund_policy')}
                    </Link>
                  </Typography>
                }
              />
              <LoadingButton 
                type='submit'
                loading={mutation.isLoading}
                variant={'contained'}
                sx={{
                  boxShadow: 8,
                  textTransform: 'none',
                  fontFamily: 'Catamaran Roman Bold',
                  [theme.breakpoints.up('md')]: {
                    borderRadius: '50px',
                    height: '50px',
                    width: '200px',
                    fontSize: '22px'
                  },
                  [theme.breakpoints.down('md')]: {
                    borderRadius: '20px',
                    height: '40px',
                    width: '150px',
                    fontSize: '18px'
                  },
                  [theme.breakpoints.down('sm')]: {
                    width: '125px',
                    fontSize: '16px',
                    marginTop: '12px'
                  }
                }}
              >
                {texts('auth:sign_up')}
              </LoadingButton>

              <Box component={'span'}>
                <Typography 
                  fontFamily={'Roboto'}
                  sx={{
                    fontFamily: 'Roboto',
                    [theme.breakpoints.up('md')]: {
                      lineHeight: '20px',
                      fontSize: '16px'
                    },
                    [theme.breakpoints.down('md')]: {
                      lineHeight: '18px',
                      fontSize: '14px'
                    }
                  }}
                  fontWeight={400}
                  color={'#404040'}
                  display={'inline'}
                >
                  {texts('auth:sign_in_description')}
                </Typography>
                <Link
                  paddingLeft={{ xs: 0.5 }}
                  href='/'
                  display={'inline'}
                  underline={'none'}
                  sx={{
                    fontFamily: 'Roboto Medium',
                    fontWeight: 400,
                    [theme.breakpoints.up('md')]: {
                      fontSize: '16px'
                    },
                    [theme.breakpoints.down('md')]: {
                      fontSize: '14px'
                    }
                  }}
                >
                  {texts('auth:sign_in')}
                </Link>
              </Box>
            </Grid>
          </Form>
        </Formik>
      </AuthLayout>  
    </>
  )
}

export default SignUp