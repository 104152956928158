import { Api } from '../Api'
import { BenfodDataDetailsDto, CompanyHintDto, CompanyTileDto, CompanyWithBenfordDto, QueryResultDto, SearchOptionsDto } from './types'
import { AccessStateDto } from '../auth/types';

class DataApi extends Api {
  private constructor() {
    super({
      baseURL: `${process.env.REACT_APP_BASE_DATA}/api/v1/data/`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
  private static instance: DataApi

  static getInstance(): DataApi {
    if (DataApi.instance) {
      return this.instance
    }
    return this.instance = new DataApi()
  }

  public searchCompanies(options: SearchOptionsDto): Promise<QueryResultDto<CompanyTileDto> & Pick<AccessStateDto, 'accessStatus'>> {
    return this.get<QueryResultDto<CompanyTileDto> & Pick<AccessStateDto, 'accessStatus'>>('company/search', {
      params: {
        ...(options.limit != null ? { limit: options.limit } : {}),
        ...(options.offset != null ? { offset: options.offset } : {}),
        ...(options.order ? { order: options.order } : {}),
        ...(options.search ? { search: options.search } : {})
      }
    })
  }

  public hintCompanies(search: string): Promise<CompanyHintDto[]> {
    return this.get<CompanyHintDto[]>('company/hints', {
      params: {
        search: search
      }
    })
  }

  public findCompanyByUuid(uuid: string, order?: string): Promise<CompanyWithBenfordDto> {
    return this.get<CompanyWithBenfordDto>('company/uuid/find', {
      params: {
        uuid: uuid,
        order: order
      }
    })
  }

  public getBenfordDetails(uuid: string): Promise<BenfodDataDetailsDto> {
    return this.get<BenfodDataDetailsDto>(`benford/${uuid}/details`)
  }
}

export default DataApi