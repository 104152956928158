import { TextField as MuiTextField, TextFieldProps } from '@mui/material'
import { useField } from 'formik'

const TextField = (props: TextFieldProps) => {
  const [field, meta] = useField(props.name!)

  const configTextField: TextFieldProps = {
    ...field,
    ...props,
    fullWidth: true,
    variant: 'outlined'
  }

  if (meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  }

  return (
    <MuiTextField {...configTextField}/> 
  )
}

export default TextField
