import { Grid, Paper, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import MainLayout from '../../components/layouts/MainLayout'
import { useLocation } from 'react-router-dom'
import Refresh from '../../components/common/Refresh'
import Loader from '../../components/common/Loader'
import SubscribeNowDialog from '../../components/subscriptions/SubscribeNowDialog'
import CompanyListTopSection from '../../components/company/list/CompanyListTopSection'
import CompanyListBottomSection from '../../components/company/list/CompanyListBottomSection'
import { useEffect, useState } from 'react'
import SubscriptionRenewalDialog from '../../components/subscriptions/SubscriptionRenewalDialog'
import useSearchCompanies from '../../hooks/data/useSearchCompanies'
import { useQueryClient } from 'react-query'
import { queryNames } from '../../hooks/queries'
import store from '../../redux/store'
import {
  useQueryParams,
  NumberParam,
  withDefault,
  StringParam,
} from 'use-query-params'
import { getUserUuid } from '../../services/storage/StorageServices'
import { AccessStatus } from '../../redux/storageToolkit'
import { Box } from '@mui/system'
import { Helmet } from 'react-helmet'

type SubscriptionState = {
  openRenewSubscribeDialog?: boolean
}

const companiesPerPage = Number.parseInt(process.env.REACT_APP_COMPANIES_PER_PAGE!)

function Home() {
  const texts = useTranslation().t
  const queryClient = useQueryClient()
  const location = useLocation()
  const theme = useTheme()
  const subscribed = store.getState().storage.accessStatus === AccessStatus.ACTIVE

  const [searchParams, setSearchParams] = useQueryParams({ 
    page: withDefault(NumberParam, 1),
    search: withDefault(StringParam, undefined)
  })

  const [subscribeNowDialogOpened, setSubscribeNowDialogOpened] = useState<boolean>(false)
  const [renewSubscriptionDialogOpened, setRenewSubscriptionDialogOpened] = useState<boolean>(false)
  
  const searchCompaniesWithPagination = useSearchCompanies({
    search: searchParams.search ? decodeURI(searchParams.search) : undefined,
    limit: companiesPerPage,
    offset: (searchParams.page - 1) * companiesPerPage,
    order: 'name:asc'
  })

  useEffect(() => {
    if ((location.state as SubscriptionState)?.openRenewSubscribeDialog) {
      setRenewSubscriptionDialogOpened(true)
    }
  }, [location.state])

  useEffect(() => {
    const maxPage = (searchCompaniesWithPagination.data?.totalCount ?? 0) > 0 ? Math.ceil((searchCompaniesWithPagination.data!.totalCount) / companiesPerPage) : 1
    const redirect = (!Number.isInteger(searchParams.page) || searchParams.page < 1 ) || (!searchCompaniesWithPagination.isLoading && searchParams.page > maxPage)

    if (redirect) {
      setSearchParams({ page: 1, search: searchParams.search }, 'pushIn')
      return
    }

    if ((!subscribed && searchParams.page > 1)) {
      setSearchParams({ page: 1 }, 'replaceIn')
    }
  }, [searchParams, subscribed, setSearchParams, searchCompaniesWithPagination.data, searchCompaniesWithPagination.isLoading])


  const showSubscriptionDialog = (): boolean => {
    switch (store.getState().storage.accessStatus) {
      case AccessStatus.PAST_DUE:
        setRenewSubscriptionDialogOpened(true)
        return true
      case AccessStatus.INACTIVE:
        setSubscribeNowDialogOpened(true)
        return true
      default:
        return false
    }
  }

  return (
    <>
      <Helmet>
        <title>{texts('pageTitles:home')}</title>
      </Helmet>
      <MainLayout>
        {
          searchCompaniesWithPagination.isError
          ? <Grid>
              {
                searchCompaniesWithPagination.isError
                ? <Refresh
                    onClick={() => {
                      queryClient.invalidateQueries([queryNames.companiesTile, getUserUuid(), (searchParams.page - 1) * companiesPerPage])
                    }}
                  />
                : <Loader
                    width={'100%'}
                    height={'calc(100vh - 160px)'}
                    text={texts('common:loading')}
                  />
              }
            </Grid>
          : <Paper
              elevation={0}
              sx={{
                marginTop: '5px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                [theme.breakpoints.up('md')]:{
                  marginTop: '60px',
                  paddingBottom: 0
                },
                [theme.breakpoints.down('md')]:{
                  marginTop: '30px',
                  paddingBottom: 0
                },
                [theme.breakpoints.down('sm')]:{
                  marginTop: '20px',
                  paddingBottom: '20px'
                }
              }}  
            >
              <SubscribeNowDialog 
                open={subscribeNowDialogOpened}
                onClose={() => setSubscribeNowDialogOpened(false)}
              />
              <SubscriptionRenewalDialog
                open={renewSubscriptionDialogOpened}
                onClose={() => {
                  window.history.replaceState({}, document.title)
                  setRenewSubscriptionDialogOpened(false)
                }}
              />
              <CompanyListTopSection
                searchValue={searchParams.search ? decodeURI(searchParams.search) : undefined }
                searchBarDisabled={!subscribed}
                onSearchClick={(data) => {
                  setSearchParams({ search: data.search ? encodeURI(data.search) : undefined, page: 1 }, 'replaceIn')
                  queryClient.invalidateQueries([queryNames.companiesTile, getUserUuid(), (searchParams.page - 1) * companiesPerPage, searchParams.search])
                }}
                onClearIconClick={() => setSearchParams({ search: undefined, page: 1 }, 'replaceIn')}
              />
              {
                searchCompaniesWithPagination.isLoading
                  ? <Box
                      height={'400px'}
                    >
                      <Loader
                        width={'100%'}
                        text={texts('common:loading')}
                      />
                    </Box>
                  : <CompanyListBottomSection
                      searched={Boolean(searchParams.search?.length)}
                      maxPages={Math.ceil((searchCompaniesWithPagination.data?.totalCount ?? 1) / companiesPerPage)}
                      currentPage={searchParams.page}
                      onPageChange={(event, value) => {
                        if (!showSubscriptionDialog()) {
                          setSearchParams({ page: value, search: searchParams.search }, 'push')
                        }
                      }}
                      onLockedTileClick={() => {
                        showSubscriptionDialog()
                      }}
                      directToUrl={(company) => `/companies/${company.uuid}`}
                      companies={searchCompaniesWithPagination.data?.data ?? []}
                    />
              }
            </Paper>
        }
      </MainLayout>
    </>
  )
}

export default Home