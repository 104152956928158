import { PasswordDto, CodeDto, EmailDto } from '../../api/auth/types'
import AuthApi from '../../api/auth/AuthApi'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'
import { HttpError } from '../../types/common'
import { Codes, AuthSubcodes } from '../../services/errors/consts'
import { useNavigate } from 'react-router-dom'
import { showErrorToast } from '../../utils/error'

type Resent = {
  resent?: boolean
}

const authApi = AuthApi.getInstance()

export function useResetPassword() {
  const texts = useTranslation().t
  const navigate = useNavigate()

  const mutation = useMutation((data: PasswordDto) => authApi.finishPasswordReset(data), {
    onSuccess: () => {
      toast.success(texts('successes:reset_password_successful'))
    },
    onError: (error: AxiosError<HttpError>) => {
      const isCookieExpired = error.response?.data.code === Codes.AUTH && error.response?.data.subcode === AuthSubcodes.ONE_TIME_COOKIE_EXPIRED
      if (isCookieExpired) {
        navigate('/password/reset')
      }
      showErrorToast(error, texts)
    }
  })
  return mutation
}

export function useCheckToken() {
  const texts = useTranslation().t
  
  const mutation = useMutation((data: CodeDto & EmailDto) => authApi.checkResetPasswordToken(data), {
    onError: (error) => {
      showErrorToast(error, texts)
    },
  })
  return mutation
}

export function useSendResetPasswordRequest(options?: Resent) {
  const texts = useTranslation().t

  const mutation = useMutation((data: EmailDto) => authApi.sendResetPasswordRequest(data), {
    onSuccess: () => {
      toast.success(texts(options?.resent ? 'successes:reset_password_mail_resent' : 'successes:reset_password_mail_sent'))
    },
    onError: (error) => {
      showErrorToast(error, texts)
    },
  })
  return mutation
}