import { Grid, Typography } from '@mui/material'
import { ClipLoader } from 'react-spinners'

type Props = {
  text?: string
  color?: string
  backgroundColor?: string
  width?: string
  height?: string
  size?: number
}

const Loader = (props: Props) => {
  return (
    <Grid
      container
      width={props.width ?? '100%'}
      height={props.height ?? '100%'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Grid
        item
        container
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'0.5rem'}
        my={'0.5rem'}
      >
        <ClipLoader 
          size={props.size ?? 80}
          speedMultiplier={0.75}
          color={props.color ?? '#000'}
        />
        {
          props.text
            ? <Typography 
                variant='h4' 
                sx={{color: props.color ?? '#000'}}
              >
                {props.text}
              </Typography>
            : null
        }
      </Grid>
    </Grid>
  )
}

export default Loader