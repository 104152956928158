import { Form, Formik } from 'formik'
import { EmailDto } from '../../api/auth/types'
import AuthLayout from '../../components/layouts/AuthLayout'
import { resetPasswordRequestValidation } from '../../utils/validations/auth'
import { useTranslation } from 'react-i18next'
import { Grid, Typography, useTheme } from '@mui/material'
import TextField from '../../components/customMui/TextFields'
import { LoadingButton } from '@mui/lab'
import {useNavigate } from 'react-router-dom'
import { useSendResetPasswordRequest } from '../../hooks/auth/useResetPassword'
import { Helmet } from 'react-helmet'

function ResetPasswordRequest() {
  const theme = useTheme()
  const texts = useTranslation().t
  const navigate = useNavigate()
  const mutation = useSendResetPasswordRequest()

  const handleSubmit = (data: EmailDto) => {
    mutation.mutate(data, {
      onSuccess: () => {
        navigate('/password/reset/verify', {
          replace: true,  
          state: {
            email: data.email
          }
        })
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>{texts('pageTitles:reset_password_request')}</title>
      </Helmet>
      <AuthLayout>
        <Formik<EmailDto>
          initialValues={{
            email: ''
          }}
          onSubmit={handleSubmit}
          validationSchema={resetPasswordRequestValidation(texts)}
          validateOnChange={true}
          validateOnBlur={true}
        >
          <Form>
            <Grid
              gap={{ xs: 2, sm: 3, md: 4 }}
              container
              direction={'column'}
              alignItems={'center'}
              width={'100%'}
            >
              <Typography variant={'subtitle1'}>{texts('auth:forgot_password_title')}</Typography>
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  [theme.breakpoints.up('md')]: {
                    lineHeight: '20px',
                    fontSize: '16px'
                  },
                  [theme.breakpoints.down('md')]: {
                    lineHeight: '18px',
                    fontSize: '14px'
                  }
                }}
              >
                {texts('auth:forgot_password_subtitle')}
              </Typography>

              <TextField
                name='email'
                type='email'
                label={texts('auth:email_address')}
              />

              <LoadingButton
                type='submit'
                variant={'contained'}
                loading={mutation.isLoading}
                sx={{
                  boxShadow: 8,
                  textTransform: 'none',
                  fontFamily: 'Catamaran Roman Bold',
                  [theme.breakpoints.up('md')]: {
                    borderRadius: '50px',
                    height: '50px',
                    width: '200px',
                    fontSize: '22px'
                  },
                  [theme.breakpoints.down('md')]: {
                    borderRadius: '20px',
                    height: '40px',
                    width: '150px',
                    fontSize: '18px'
                  },
                  [theme.breakpoints.down('sm')]: {
                    width: '125px',
                    fontSize: '14px'
                  }
                }}
              >
                {texts('auth:send')}
              </LoadingButton>
            </Grid>
          </Form>
        </Formik>
      </AuthLayout>  
    </>
  )
}

export default ResetPasswordRequest