import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query';
import DataApi from '../../api/data/DataApi'
import { queryNames } from '../queries'
import { useState, useEffect } from 'react'
import { getUserUuid } from '../../services/storage/StorageServices'
import { AxiosError } from 'axios'
import { HttpError } from '../../types/common'
import { AuthSubcodes, Codes } from '../../services/errors/consts'
import { showErrorToast } from '../../utils/error';

const dataApi = DataApi.getInstance()

function isSubscriptionError(error: AxiosError<HttpError>): boolean {
  return error.response?.data.code === Codes.AUTH
    && (error.response?.data?.subcode === AuthSubcodes.SUBSCRIPTION_PAST_DUE
    || error.response?.data?.subcode === AuthSubcodes.USER_NOT_SUBSCRIBED)
}

export default function useGetBenfordDetails(defaultUuid?: string, ) {
  const texts = useTranslation().t
  const queryClient = useQueryClient()
  const [selectedYearUuid, setSelectedYearUuid] = useState<string>('')
  useEffect(() => {
    setSelectedYearUuid(defaultUuid ?? '')
  }, [defaultUuid])

  const benfordDataQuery = useQuery([queryNames.benfordDetails, getUserUuid(), selectedYearUuid], () => {
    return dataApi.getBenfordDetails(selectedYearUuid)
  }, {
    enabled: Boolean(selectedYearUuid),
    onError: (error: AxiosError<HttpError>) => {
      if (isSubscriptionError(error)) {
        queryClient.invalidateQueries([queryNames.companiesTile, getUserUuid()])
        return
      }

      showErrorToast(error, texts)
    }
  })

  return { benfordDataQuery, setSelectedYearUuid }
}
