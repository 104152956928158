// import { Autocomplete, InputAdornment, useTheme, TextFieldProps, IconButton, Button } from '@mui/material';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
// import { Field, FormikProps } from 'formik'
// import { LoadingButton } from '@mui/lab'
// import { useTranslation } from 'react-i18next'
// import { CompanyHintDto, SearchDto } from '../../../api/data/types'
// import TextField from '../../customMui/TextFields'
// import CloseIcon from '@mui/icons-material/Close'

// type Props = {
//   disabled: boolean
//   isSearchButtonLoading: boolean
//   isHintListLoading: boolean
//   name: string
//   options: CompanyHintDto[]
//   formikProps: FormikProps<SearchDto>
//   onHintSelected: (e: Event, value: CompanyHintDto) => void
//   onInputChange: (selected: string) => void
//   onClearIconClick: () => void
// }

// function FormikSearchBar(props: Props) {
//   const texts = useTranslation().t
//   const theme = useTheme()
  
//   return (
//     <Field
//       disabled={props.disabled}
//       name={props.name}
//       component={TextField}
//       placeholder={texts('companies:search_placeholder')}
//       // sx={{
//       //   '.MuiInput-root': {
//       //     alignContent: 'center',
//       //     paddingRight: '0 !important',
//       //     '.MuiInput-input': {
//       //       fontFamily: 'Roboto',
//       //       [theme.breakpoints.up('md')]: {
//       //         fontSize: '20px'
//       //       },
//       //       [theme.breakpoints.down('md')]: {
//       //         fontSize: '14px'
//       //       },
//       //       [theme.breakpoints.down('sm')]: {
//       //         fontSize: '12px'
//       //       }
//       //     },
//       //     '.MuiAutocomplete-root': {
//       //       paddingRight: 0
//       //     }
//       //   },
//       // }}
//       sx={{
//         boxShadow: 4,
//         borderRadius: '30px',
//         width: '100%',
//         [theme.breakpoints.up('md')]: {
//           height: '56px'
//         },
//         [theme.breakpoints.down('md')]: {
//           height: '40px'
//         },
//         [theme.breakpoints.down('sm')]: {
//           height: '30px'
//         }
//       }}
//       value={props.formikProps.values.search}
//       onChange={(e: Event, value: CompanyHintDto | string) => {
//         if (typeof value !== 'string') {
//           props.onHintSelected(e, value)
//         }
//       }}
//       onInputChange={(e: Event, value: string) => props.onInputChange(value)}
//       endAdornment={(
//         <InputAdornment position={'end'}>
//           <IconButton
//             sx={{
//               display: props.formikProps.values.search ? 'auto' : 'none'
//             }}
//             onClick={props.onClearIconClick}
//           >
//             <CloseIcon
//               sx={{
//                 '&:hover': {
//                   animationDuration: '1s',
//                   animationTimingFunction: 'ease-in-out',
//                   animationDelay: '0s',
//                   animationIterationCount: '1',
//                   animationName: 'rotate',
//                   '@keyframes rotate': {
//                     '100%': { transform: 'rotate(360deg)' }
//                   }
//                 }
//               }}
//             />
//           </IconButton>
//           <LoadingButton
//             disabled={props.disabled}
//             loading={props.isSearchButtonLoading}
//             type={'submit'}
//             variant={'contained'}
//             endIcon={
//               <ArrowForwardIcon
//                 sx={{
//                   [theme.breakpoints.up('md')]: {
//                     width: '32px',
//                     height: '32px'
//                   },
//                   [theme.breakpoints.down('md')]: {
//                     width: '18px',
//                     height: '24px'
//                   },
//                   [theme.breakpoints.down('sm')]: {
//                     width: '16px',
//                     height: '16px'
//                   }
//                 }}
//               />
//             }
//             sx={{
//               textTransform: 'none',
//               boxShadow: 0,
//               fontFamily: 'Catamaran Roman Bold',
//               [theme.breakpoints.up('md')]: {
//                 width: '200px',
//                 height: '56px',
//                 borderRadius: '50px',
//                 fontSize: '24px'
//               },
//               [theme.breakpoints.down('md')]: {
//                 width: '126px',
//                 height: '40px',
//                 borderRadius: '50px',
//                 fontSize: '16px'
//               },
//               [theme.breakpoints.down('sm')]: {
//                 width: '100px',
//                 height: '30px',
//                 borderRadius: '30px',
//                 fontSize: '14px'
//               }
//             }}
//           >
//             {texts('companies:search')}
//           </LoadingButton>
//         </InputAdornment>
//       )}
//     />
//   )
// }

// export default FormikSearchBar

import { TextField as MuiTextField, TextFieldProps, InputAdornment, IconButton, Button } from '@mui/material'
import { useTheme } from '@mui/system'
import { FormikProps, useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { SearchDto } from '../../../api/data/types'
import CloseIcon from '@mui/icons-material/Close'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

type Props = {
  disabled: boolean
  name: string
  formikProps: FormikProps<SearchDto>
  onInputChange: (selected: string) => void
  onClearIconClick: () => void
}

function FormikSearchBar(props: Props) {
  const [field, meta] = useField(props.name)
  const theme = useTheme()
  const texts = useTranslation().t

  const configTextField: TextFieldProps = {
    ...field,
    name: props.name,
    fullWidth: true,
    variant: 'standard',
  }

  if (meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  }

  return (
    <MuiTextField
      {...configTextField}
      value={props.formikProps.values.search ?? ''}
      disabled={props.disabled}
      placeholder={texts('companies:search_placeholder')}
      sx={{
        '.MuiInput-root': {
          alignContent: 'center',
          paddingRight: '0 !important',
          borderRadius: '30px',
          paddingTop: '2px',
          paddingBottom: '2px',
          [theme.breakpoints.up('md')]: {
            height: '56px',
            paddingLeft: '22px'
          },
          [theme.breakpoints.down('md')]: {
            height: '40px',
            paddingLeft: '22px'
          },
          [theme.breakpoints.down('sm')]: {
            height: '40px',
            paddingLeft: '22px'
          },
          '.MuiInput-input': {
            [theme.breakpoints.up('md')]: {
              fontSize: '20px'
            },
            [theme.breakpoints.down('md')]: {
              fontSize: '14px'
            },
            [theme.breakpoints.down('sm')]: {
              fontSize: '12px'
            }
          }
        },
        borderRadius: '30px',
        boxShadow: 3,
        fontFamily: 'Roboto',
        maxWidth: '100%'
      }}
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <InputAdornment position={'end'} >
            <IconButton
              sx={{
                display: props.formikProps.values.search ? 'auto' : 'none'
              }}
              onClick={props.onClearIconClick}
            >
              <CloseIcon
                sx={{
                  '&:hover': {
                    animationDuration: '1s',
                    animationTimingFunction: 'ease-in-out',
                    animationDelay: '0s',
                    animationIterationCount: '1',
                    animationName: 'rotate',
                    '@keyframes rotate': {
                      '100%': { transform: 'rotate(360deg)' }
                    }
                  }
                }}
              />
            </IconButton>
            <Button
              disabled={props.disabled}
              type={'submit'}
              variant={'contained'}
              endIcon={
                <ArrowForwardIcon
                  sx={{
                    [theme.breakpoints.up('md')]: {
                      width: '32px',
                      height: '32px'
                    },
                    [theme.breakpoints.down('md')]: {
                      width: '18px',
                      height: '24px'
                    },
                    [theme.breakpoints.down('sm')]: {
                      width: '18px',
                      height: '24px'
                    }
                  }}
                />
              }
              sx={{
                textTransform: 'none',
                boxShadow: 0,
                fontFamily: 'Catamaran Roman Bold',
                [theme.breakpoints.up('md')]: {
                  width: '200px',
                  height: '56px',
                  borderRadius: '50px',
                  fontSize: '24px'
                },
                [theme.breakpoints.down('md')]: {
                  width: '126px',
                  height: '40px',
                  borderRadius: '50px',
                  fontSize: '16px'
                },
                [theme.breakpoints.down('sm')]: {
                  width: '126px',
                  height: '40px',
                  borderRadius: '30px',
                  fontSize: '16px'
                }
              }}
            >
              {texts('companies:search')}
            </Button>
          </InputAdornment>
        )
      }}
    /> 
  )
}

export default FormikSearchBar
