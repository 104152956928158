import { Box, Grid, Typography } from '@mui/material'
import MainLayout from '../../components/layouts/MainLayout'
import companiesImageSrc from '../../assets/images/CompanyTileImage.svg'
import { useTranslation } from 'react-i18next'
import CompanyDisplay from '../../components/company/report/CompanyDisplay'
import FormerYearsChart from '../../components/company/report/FormerYearsChart'
import FocusYearChart from '../../components/company/report/FocusYearChart'
import { useNavigate, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useWindowWidth } from '@react-hook/window-size'
import useFindCompany from '../../hooks/data/useFindCompany'
import { useQueryClient } from 'react-query'
import Refresh from '../../components/common/Refresh'
import Loader from '../../components/common/Loader'
import { queryNames } from '../../hooks/queries'
import { FocusYearBrief, CompanyHintDto } from '../../api/data/types'
import useGetBenfordDetails from '../../hooks/data/useGetBenfordDetails'
import useHintCompanies from '../../hooks/data/useHintCompanies'
import { useDebouncedCallback } from 'use-debounce'
import SearchBar from '../../components/company/report/SearchBar'
import store from '../../redux/store'
import { getUserUuid } from '../../services/storage/StorageServices'
import { AccessStatus } from '../../redux/storageToolkit'
import { Helmet } from 'react-helmet'

function mapFocusYears(focusYears: FocusYearBrief[]) {
  const options: { label: string, value: string }[] = []
  
  for (const fy of focusYears) {
    if (fy.data.length === 1) {
      options.push({
        label: fy.focusYear.toString(),
        value: fy.data[0].uuid
      })
    } else {
      options.push(...fy.data.map((submission, index) => ({
        label: `${fy.focusYear} (${fy.data.length - index})`,
        value: submission.uuid
      })))
    }
  }

  return options
}

function mapHintLabel(option: CompanyHintDto | string) {
  return typeof option === 'string'
    ? option
    : `${option.name} (CIK: ${option.cik})`
}

function CompanyReport() {
  const subscribed = store.getState().storage.accessStatus === AccessStatus.ACTIVE
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const navigate = useNavigate()
  const {companyUuid} = useParams()
  const [mobileSearchVisible, setMobileSearchVisible] = useState<boolean>(false)
  
  const onlyWidth = useWindowWidth({ wait: 50 })

  const { data, isLoading, isError } = useFindCompany(companyUuid!, {
    order: 'years:desc,accepted:desc'
  })
  const { benfordDataQuery, setSelectedYearUuid} = useGetBenfordDetails(data?.focusYears[0].data[0].uuid)

  const [hintSearchValue, setHintSearchValue] = useState({
    previousValue: '',
    value: ''
  })
  const hints = useHintCompanies(hintSearchValue.value, hintSearchValue.previousValue, {
    enabled: Boolean(hintSearchValue.value) && subscribed,
    staleTime: 0,
    cacheTime: 30 * 1000
  })
  const debouncedHintCompanies = useDebouncedCallback((value) => {
    setHintSearchValue({
      previousValue: hintSearchValue.value,
      value: value
    })
  }, 300)

  return (
    <>
      <Helmet>
        {
          data?.info.name != null && <title>{texts('pageTitles:company_report', { companyName: data.info.name })}</title>
        }
      </Helmet>
      <MainLayout
        hideLogo={onlyWidth < 600 && mobileSearchVisible}
        hideAccountNav={onlyWidth < 600 && mobileSearchVisible}
        middleElement={
          subscribed  
          ? <SearchBar
              visible={mobileSearchVisible || onlyWidth >= 600}
              showButton={onlyWidth < 600}
              placeholder={onlyWidth < 600 ? texts('companies:search_placeholder_mobile') : texts('companies:search_placeholder')}
              onChange={(event, value) => {
                if (!value || typeof value === 'string') {
                  return
                }
                navigate(`/companies/${value.uuid}`)
              }}
              onInputChange={(event, value) => {
                if (event.type === 'change') {
                  debouncedHintCompanies(value)
                  return
                }
                if (event.type === 'click') {
                  debouncedHintCompanies('')
                }
              }}
              getOptionLabel={mapHintLabel}
              options={hints.data ?? []}
              onButtonClick={() => setMobileSearchVisible(!mobileSearchVisible)}
              loading={hints.isLoading}
            />
            : undefined
          }
        > 
        {
          isLoading || isError
          ? <Grid>
              {
                isError
                  ? <Refresh
                      onClick={() => {
                       queryClient.invalidateQueries([queryNames.companyDetails, getUserUuid(), companyUuid])
                      }}
                    />
                  : <Loader
                      width={'100%'}
                      height={'calc(100vh - 160px)'}
                      text={texts('common:loading')}
                    />
              }
            </Grid>
          : <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <Grid
                container
                paddingTop={{ xs: '20px', sm: '30px', md: '60px' }}
                paddingBottom={{ xs: '56px', sm: '30px', md: '60px' }}
                maxWidth={'1300px'}
                paddingLeft={'24px'}
                paddingRight={'24px'}
                direction={'column'}
                gap={{ xs: '12px', sm: '16px', md: '26px' }}
              >
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                >
                  <Box
                    component={'img'}
                    src={companiesImageSrc}
                    width={{ xs: '32px', sm: '42px', md: '64px' }}
                    height={{ xs: '32px', sm: '42px', md: '64px' }}
                    alignSelf={'flex-start'}
                  />
                  <Typography
                    marginLeft={{ xs: '12px', md: '15px' }}
                    variant={'h2'}
                  >
                    {data?.info.name}
                  </Typography>
                </Box>

                <CompanyDisplay
                  company={data?.info!}
                  fiscalYearEnd={benfordDataQuery.data?.fiscalYearEnd}
                />

                <FocusYearChart
                  //key={companyUuid}
                  sampleSize={benfordDataQuery.data?.sampleSize ?? 0}
                  isLoading={benfordDataQuery.isLoading}
                  windowWidth={onlyWidth}
                  values={benfordDataQuery.data?.value.distributions}
                  chiSquared={benfordDataQuery.data?.value.chiSquared ?? 0}
                  defaultYear={data?.focusYears[0].data[0].uuid}
                  yearOptions={data ? mapFocusYears(data.focusYears) : []}
                  onYearSelected={(uuid) => setSelectedYearUuid(uuid)}
                />
                {
                  data?.chiSquaredValues && data?.chiSquaredValues.length >= 3 
                    ? <FormerYearsChart
                        windowWidth={onlyWidth}
                        values={data?.chiSquaredValues ?? []}
                      />
                    : <></>
                }
              </Grid>
            </Box>
        }
      </MainLayout>
    </>
  )
}

export default CompanyReport