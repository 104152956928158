import MainLayout from '../../components/layouts/MainLayout'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import AccountTile from '../../components/account/AccountTile'
import { useTranslation } from 'react-i18next'
import AccountImageSrc from '../../assets/images/AccountImage.svg'
import BillingsImageSrc from '../../assets/images/BillingsImage.svg'
import useViewBillings from '../../hooks/stripe/useViewBillings'
import { Helmet } from 'react-helmet'

function Account() {
  const navigate = useNavigate()
  const texts = useTranslation().t
  const mutation = useViewBillings()

  return (
    <>
      <Helmet>
        <title>{texts('pageTitles:settings')}</title>
      </Helmet>
      <MainLayout>
        <Grid
          container
          direction={{ xs: 'column', sm: 'row'}}
          justifyContent={'center'}
          alignContent={'center'}
          gap={{ xs: '16px', sm: '10px', md: '30px' }}
          paddingTop={{ xs: '20px', sm: '30px', md: '75px' }}
          paddingLeft={{ xs: '20px', sm: '30px', md: '75px' }}
          paddingRight={{ xs: '20px', sm: '30px', md: '75px' }}
          paddingBottom={{ xs: '60px', sm: '30px', md: '75px' }}
        >
          <AccountTile
            title={texts('accounts:settings_title')}
            description={texts('accounts:settings_description')}
            imageSrc={AccountImageSrc}
            onButtonClick={() => navigate('/account/settings')}
          />
          <AccountTile
            isLoading={mutation.isLoading}
            title={texts('accounts:billings_title')}
            description={texts('accounts:billings_description')}
            imageSrc={BillingsImageSrc}
            onButtonClick={() => mutation.mutate()}
          />
        </Grid>
      </MainLayout>
    </>
  )
}

export default Account