import AuthLayout from '../../components/layouts/AuthLayout'
import { Box, Grid, Link, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Form, Formik, FormikHelpers } from 'formik'
import TextField from '../../components/customMui/TextFields'
import PasswordField from '../../components/customMui/PasswordField'
import { LoginDto } from '../../api/auth/types'
import { signInValidation } from '../../utils/validations/auth'
import { LoadingButton } from '@mui/lab'
import useLogIn from '../../hooks/auth/useLogIn'
import { useNavigate, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { AccessStatus } from '../../redux/storageToolkit'

function SignIn() {
  const texts = useTranslation().t
  const navigate = useNavigate()
  const theme = useTheme()
  const mutation = useLogIn()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname !== '/') {
      navigate('/', { replace: true })
    }
  }, [location, navigate])

  function handleSubmit(data: LoginDto, formikHelpers: FormikHelpers<LoginDto>) {
    mutation.mutate(data, {
      onSuccess: (data) => {
        navigate('/', { replace: true, state: { openRenewSubscribeDialog: data.accessStatus === AccessStatus.PAST_DUE } })
      },
      onError:() => {
        formikHelpers.setFieldValue('password', '')
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>{texts('pageTitles:sign_in')}</title>
      </Helmet>
      <AuthLayout>
        <Formik<LoginDto>
          initialValues={{
            login: '',
            password: ''
          }}
          onSubmit={handleSubmit}
          validationSchema={signInValidation(texts)}
          validateOnChange={true}
          validateOnBlur={true}
        >
          <Form>
            <Grid
              gap={{ xs: 2, sm: 3, md: 4 }}
              container
              direction={'column'}
              alignItems={'center'}
              width={'100%'}
            >
              <Typography variant={'subtitle1'}>{texts('auth:sign_in')}</Typography>
              
              <TextField
                name='login'
                type='email'
                label={texts('auth:email_address')}
              />
              <PasswordField
                name='password'
                label={texts('auth:password')} 
              />

              <Link
                marginTop={{ sm: -0.5, md: -1 }}
                href='/password/reset'
                underline={'none'}
                alignSelf={'flex-end'}
                sx={{
                  fontFamily: 'Roboto Medium',
                  fontWeight: 400,
                  [theme.breakpoints.up('md')]: {
                    fontSize: '16px'
                  },
                  [theme.breakpoints.down('md')]: {
                    fontSize: '14px'
                  }
                }}
              >
                {texts('auth:forgot_password')}
              </Link>

              <LoadingButton
                type='submit'
                variant={'contained'}
                loading={mutation.isLoading}
                sx={{
                  boxShadow: 8,
                  textTransform: 'none',
                  fontFamily: 'Catamaran Roman Bold',
                  [theme.breakpoints.up('md')]: {
                    borderRadius: '50px',
                    height: '50px',
                    width: '200px',
                    fontSize: '22px',
                  },
                  [theme.breakpoints.down('md')]: {
                    borderRadius: '20px',
                    height: '40px',
                    width: '150px',
                    fontSize: '18px',
                  },
                  [theme.breakpoints.down('sm')]: {
                    marginTop: '12px',
                    width: '125px',
                    fontSize: '16px'
                  }
                }}
              >
                {texts('auth:sign_in')}
              </LoadingButton>

              <Box component={'span'}>
                <Typography 
                  fontFamily={'Roboto'}
                  sx={{
                    fontFamily: 'Roboto',
                    [theme.breakpoints.up('md')]: {
                      lineHeight: '20px',
                      fontSize: '16px'
                    },
                    [theme.breakpoints.down('md')]: {
                      lineHeight: '18px',
                      fontSize: '14px'
                    }
                  }}
                  fontWeight={400}
                  color={'#404040'}
                  display={'inline'}
                >
                  {texts('auth:sign_up_description')}
                </Typography>

                <Link
                  paddingLeft={{ xs: 0.5 }}
                  href='/register'
                  underline={'none'}
                  sx={{
                    fontFamily: 'Roboto Medium',
                    fontWeight: 400,
                    [theme.breakpoints.up('md')]: {
                      fontSize: '16px'
                    },
                    [theme.breakpoints.down('md')]: {
                      fontSize: '14px'
                    }
                  }}
                >
                  {texts('auth:sign_up')}
                </Link>
              </Box>
            </Grid>
          </Form>
        </Formik>
        
      </AuthLayout>  
    </>
  )
}

export default SignIn