import { Box, Grid, MenuItem, Paper, Select, SelectChangeEvent, Typography, useTheme } from '@mui/material'
import { Bar } from 'react-chartjs-2'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { 
  ChartData, 
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip
 } from 'chart.js'
import CardView from './CardView'
import { DigitDistributions } from '../../../api/data/types'
import Loader from '../../common/Loader'
import Legend from '../../chart/Legend'
import { useWindowWidth } from '@react-hook/window-size'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip
)

type Props = {
  isLoading: boolean
  windowWidth: number
  defaultYear?: string
  onYearSelected: (uuid: string) => void
  yearOptions: {
    label: string
    value: string
  }[]
  sampleSize: number
  chiSquared: number
  values?: DigitDistributions
}

const expectedDistributions = [
  30.1,
  17.6,
  12.5,
  9.7,
  7.9,
  6.7,
  5.8,
  5.1,
  4.6
]

function FocusYearChart(props: Props) {
  const theme = useTheme()
  const texts = useTranslation().t
  const [selectedYearUuid, setSelectedYearUuid] = useState<string>(props.defaultYear ?? '')
  const onlyWidth = useWindowWidth()


  function handleSelectedYear(event: SelectChangeEvent<string>) {
    setSelectedYearUuid(event.target.value)
    props.onYearSelected(event.target.value)
  }

  const data: ChartData<'bar', number[]> = useMemo(() => {
    return {
      labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      datasets: [ {
        categoryPercentage: 0.65,
        label: texts('companies:focus_year_expected_value'),
        data: expectedDistributions,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: props.windowWidth < theme.breakpoints.values.sm ? 3 : 5
      }, {
        categoryPercentage: 0.65,
        label: texts('companies:focus_year_actual_value'),
        data: props.values ? Object.values(props.values).map(v => v*100) : [],
        backgroundColor: theme.palette.primary.main,
        borderRadius: props.windowWidth < theme.breakpoints.values.sm ? 3 : 5
      }]
    }
  }, [
      props.values, 
      theme.palette.primary.main,
      texts, 
      theme.palette.secondary.main, 
      props.windowWidth, 
      theme.breakpoints.values.sm
    ]
  )
  
  const options = useMemo(() => 
    props.windowWidth < theme.breakpoints.values.sm
      ? {
          scales: {
            yAxes: {
              ticks: {
                maxRotation: 0,
                minRotation: 0,
                font: {
                  size: 10
                },
              }
            },
            xAxes: {
              ticks: {
                maxRotation: 0,
                minRotation: 0,
                font: {
                  size: 10
                }
              }
            }
          }
        }
      : {
          scales: {
            yAxes: {
              ticks: {
                maxRotation: 0,
                minRotation: 0,
                font: {
                  size: 12
                }
              }
            },
            xAxes: {
              ticks: {
                maxRotation: 0,
                minRotation: 0,
                font: {
                  size: 12
                }
              }
            }
          }
        },
    [props.windowWidth, theme.breakpoints.values.sm]
  )

  const selectableYears = useMemo(() => 
    props.yearOptions.map(option => (
      <MenuItem value={option.value} key={option.value}>{option.label}</MenuItem>
    )
  ), [props.yearOptions])

  return (
    <CardView
      title={texts('companies:focus_years_chart_title')}
    >
      {
        props.isLoading
        ? <Grid 
            sx={{ 
              width: '100%',
              [theme.breakpoints.up('md')]: {
                height: '567px'
              },
              [theme.breakpoints.down('md')]: {
                height: '342px'
              },
              [theme.breakpoints.down('sm')]: {
                // tu trzeba sprawdzic, dla wymienionych szerokosci tytuly i opis selectu
                // sie lamie w linii, a canvas nie ma stalego rozmiaru i nie mozna wskazac konkretnej zależnosci
                height: onlyWidth > 460 
                  ? '292px'
                  : onlyWidth > 380
                    ? '292px'
                    : '304px'
              }
            }} 
          >
            <Loader
              width={'100%'}
              text={texts('common:loading')}
            />
          </Grid>
        : <>
          <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={{ xs:'flex-end', sm: 'space-between'}}
            marginBottom={'-6px'}
          >
            {
              props.windowWidth >= 600 && 
              <Box
                sx={{
                  width: 'fit-content',
                  padding: '2px 10px 2px 10px',
                  borderRadius: '4px',
                  backgroundColor: '#EBEBFF',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center'
                }}
              >
                <Typography
                  fontFamily={'Roboto Bold'}
                  fontSize={{ sm: '14px', md: '18px' }}
                >
                  {texts('companies:focus_year_chi_square', {
                    value: props.chiSquared.toFixed(2),
                  })}
                </Typography>
                <Typography
                  fontFamily={'Roboto'}
                  fontSize={{ sm: '14px', md: '18px' }}
                  marginLeft={'7px'}
                >
                  {texts('companies:focus_year_sample_size', {
                    sampleSize: props.sampleSize
                  })}
                </Typography>
              </Box>
            }
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              marginTop={{ xs: '8px', sm: 0 }}
            >
              <Typography
                fontStyle={'Roboto'}
                fontSize={{ xs: '14px', md: '16px' }}
                lineHeight={{ xs: '18px', md: '20px' }}
                marginRight={{ xs: '6px', sm: '8px', md: '10px' }}
                marginLeft={'3px'}
              >
                {texts('companies:focus_year_select_description')}
              </Typography>
              <Select
                sx={{
                  [theme.breakpoints.up('md')]: {
                    height: '40px',
                    width: '126px',
                    fontSize: '16px',
                    lineHeight: '20px'
                  },
                  [theme.breakpoints.down('md')]: {
                    height: '30px',
                    width: '100px',
                    fontSize: '14px',
                    lineHeight: '18px'
                  },
                  [theme.breakpoints.down('sm')]: {
                    height: '30px',
                    width: '100px',
                    fontSize: '14px',
                    lineHeight: '18px'
                  },
                }}
                value={selectedYearUuid}
                onChange={handleSelectedYear}
              >
                {selectableYears}
              </Select>
            </Box>
          </Box>

          <Paper
            elevation={0}
            sx={{
              width: '100%',
              [theme.breakpoints.up('md')]: {
                height: '460px'
              },
              [theme.breakpoints.down('md')]: {
                height: '276px'
              },
              [theme.breakpoints.down('sm')]: {
                height: '200px'
              }
            }}
          >
            <Bar
              data={data}
              options={{
                scales: {
                  yAxes: {
                    ticks: {
                      ...options.scales.yAxes.ticks,
                      callback: function (val, index) {
                        return `${val}%`
                      }
                    },
                  },
                  xAxes: {
                    ...options.scales.xAxes
                  }
                },
                normalized: true,
                plugins: {
                  legend: {
                    display: false
                  },
                  tooltip: {
                    callbacks: {
                      label: (item) => `${item.dataset.label}: ${Number.parseFloat(item.formattedValue.replace(',', '.')).toFixed(2)}%`
                    }
                  },
                },
                maintainAspectRatio: false
              }}
            />
          </Paper>
          <Legend
            labels={[{
              color: theme.palette.secondary.main,
              name: texts('companies:focus_year_expected_value')
            }, {
              color: theme.palette.primary.main,
              name: texts('companies:focus_year_actual_value')
            }]}
          />
          {
            props.windowWidth < 600 &&
            <Box
              sx={{
                width: '100%',
                borderRadius: '4px',
                backgroundColor: '#EBEBFF',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <Typography
                fontFamily={'Roboto Bold'}
                fontSize={'14px'}
              >
                {texts('companies:focus_year_chi_square', {
                  value: props.chiSquared.toFixed(2),
                })}
              </Typography>
              <Typography
                fontFamily={'Roboto'}
                fontSize={'14px'}
                marginLeft={'7px'}
              >
                {texts('companies:focus_year_sample_size', {
                  sampleSize: props.sampleSize
                })}
              </Typography>
            </Box>
          }
        </>
      }
    </CardView>
    
  )
}

export default FocusYearChart