import { createTheme } from '@mui/material/styles'
import { Theme } from '@mui/material'

let MainTheme: Theme = createTheme({})
MainTheme = createTheme(MainTheme, {
  palette: {
    primary: {
      main: '#6236FF',
      dark: '#3A2099'
    },
    secondary: {
      main: '#C5C5FF'
    }
  },
  typography: {
    subtitle1: {
      fontFamily: 'Catamaran Roman Bold',
      [MainTheme.breakpoints.up('md')]: {
        fontSize: '34px'
      },
      [MainTheme.breakpoints.down('md')]: {
        fontSize: '26px'
      },
      [MainTheme.breakpoints.down('sm')]: {
        fontSize: '20px'
      }
    },
    subtitle2: {
      fontFamily: 'Roboto',
      [MainTheme.breakpoints.up('md')]: {
        lineHeight: '20px',
        fontSize: '16px'
      },
      [MainTheme.breakpoints.down('md')]: {
        lineHeight: '18px',
        fontSize: '14px'
      }
    },
    h2: {
      fontFamily: 'Catamaran Roman Bold',
      [MainTheme.breakpoints.up('md')]: {
        fontSize: '34px'
      },
      [MainTheme.breakpoints.down('md')]: {
        fontSize: '20px'
      }
    },
    h3: {
      fontFamily: 'Catamaran Roman Bold',
      [MainTheme.breakpoints.up('md')]: {
        fontSize: '18px'
      },
      [MainTheme.breakpoints.down('md')]: {
        fontSize: '14px'
      },
      [MainTheme.breakpoints.down('sm')]: {
        fontSize: '12px'
      }
    },
    h4: {
      fontFamily: 'Roboto Medium',
      fontWeight: 400,
      [MainTheme.breakpoints.up('md')]: {
        fontSize: '16px'
      },
      [MainTheme.breakpoints.down('md')]: {
        fontSize: '14px'
      },
      [MainTheme.breakpoints.down('sm')]: {
        fontSize: '12px'
      }
    },
    h5: {
      fontFamily: 'Roboto Light',
      fontWeight: 400,
      opacity: '75%',
      [MainTheme.breakpoints.up('md')]: {
        fontSize: '16px',
      },
      [MainTheme.breakpoints.down('md')]: {
        fontSize: '0.75rem',
      },
      [MainTheme.breakpoints.down('sm')]: {
        fontSize: '0.625rem',
      }
    }
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          // [MainTheme.breakpoints.up('md')]: {
          //   marginTop: '64px',
          //   marginBottom: '112px'
          // },
          // [MainTheme.breakpoints.down('md')]: {
          //   marginTop: '64px',
          //   marginBottom: '84px'
          // },
          // [MainTheme.breakpoints.down('sm')]: {
          //   marginTop: '56px',
          //   marginBottom: '114px'
          // },
          // '.MuiBackdrop-root': {
          //   marginTop: 'inherit',
          //   marginBottom: 'inherit'
          // }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          alignContent: 'center',
          '.MuiInputLabel-root': {
            [MainTheme.breakpoints.up('md')]: {
              fontSize: '16px',
              transform: 'translate(14px, 17px) scale(1)'
            },
            [MainTheme.breakpoints.down('md')]: {
              fontSize: '12px',
              transform: 'translate(14px, 13px) scale(1)'
            },
          },
          '.MuiInputLabel-root.Mui-focused' : {
            [MainTheme.breakpoints.up('md')]: {
              fontSize: '16px',
              transform: 'translate(14px, -9px) scale(0.75)'
            },
            [MainTheme.breakpoints.down('md')]: {
              fontSize: '12px',
              transform: 'translate(14px, -9px) scale(1)'
            },
          },
          '.MuiFormLabel-filled' : {
            [MainTheme.breakpoints.up('md')]: {
              fontSize: '16px',
              transform: 'translate(14px, -9px) scale(0.75)'
            },
            [MainTheme.breakpoints.down('md')]: {
              fontSize: '12px',
              transform: 'translate(14px, -9px) scale(1)'
            },
          },
          '.MuiOutlinedInput-root': {
            borderRadius: '4px',
            width: '100%',
            [MainTheme.breakpoints.up('md')]: {
              height: '56px',
            },
            [MainTheme.breakpoints.down('md')]: {
              height: '44px',
            },
            alignContent: 'center',
            '.MuiOutlinedInput-input': {
              [MainTheme.breakpoints.up('md')]: {
                fontSize: '16px',
                paddingTop: '15px',
                paddingBottom: '15px'
              },
              [MainTheme.breakpoints.down('md')]: {
                fontSize: '12px',
                paddingTop: '11px',
                paddingBottom: '11px'
              }
            }
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#3A2099'
          }
        }
      }
    },
    MuiLoadingButton: {
      styleOverrides: {
        loadingIndicator: () => ({
          color: 'white'
        }),
        root: {
          '&.MuiLoadingButton-loading': {
            backgroundColor: '#3A2099'
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FAFAFA'
        }
      }
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: '#404040',
          [MainTheme.breakpoints.up('md')]: {
            height: '106px',
          },
          [MainTheme.breakpoints.down('md')]: {
            height: '96px',
          },
          [MainTheme.breakpoints.down('sm')]: {
            height: '126px',
          }
        },
      }
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          flexDirection: 'row',
          maxWidth: 'none',
          justifyContent: 'left',
          padding: 0
        },
        label: {
          fontFamily: 'Roboto',
          fontWeight: 400,
          color: 'white',
          marginLeft: '6px',
          [MainTheme.breakpoints.up('md')]: {
            fontSize: '16px'
          },
          [MainTheme.breakpoints.down('md')]: {
            fontSize: '12px'
          }
        }
      }
    }
  }
})

export default MainTheme