import { Form, Formik } from 'formik'
import { PasswordDto } from '../../api/auth/types'
import AuthLayout from '../../components/layouts/AuthLayout'
import { resetPasswordFinishValidation } from '../../utils/validations/auth'
import { useTranslation } from 'react-i18next'
import { Grid, Typography, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useNavigate, useLocation } from 'react-router-dom'
import PasswordField from '../../components/customMui/PasswordField'
import { useResetPassword } from '../../hooks/auth/useResetPassword'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'


function ResetPasswordFinish() {
  const theme = useTheme()
  const texts = useTranslation().t
  const navigate = useNavigate()
  const mutation = useResetPassword()
  const state = useLocation().state as { enteredFromApp: boolean } | null

  useEffect(() => {
    if (!state?.enteredFromApp) {
      navigate('/')
    }
  }, [])

  function handleSubmit(data: PasswordDto) {
    mutation.mutate({
      password: data.password,
    }, {
      onSuccess: () => {
        navigate('/login', { replace: true })
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>{texts('pageTitles:reset_password_finish')}</title>
      </Helmet>
      <AuthLayout>
        <Formik<PasswordDto>
          initialValues={{
            password: ''
          }}
          onSubmit={handleSubmit}
          validationSchema={resetPasswordFinishValidation(texts)}
          validateOnChange={true}
          validateOnBlur={true}
        >
          <Form>
            <Grid
              gap={{ xs: 2, sm: 3, md: 4 }}
              container
              direction={'column'}
              alignItems={'center'}
              width={'100%'}
            >
              <Typography variant={'subtitle1'}>{texts('auth:new_password_title')}</Typography>

              <PasswordField
                name='password'
                label={texts('auth:password')} 
              />

              <LoadingButton
                type='submit'
                variant={'contained'}
                loading={mutation.isLoading}
                sx={{
                  boxShadow: 8,
                  textTransform: 'none',
                  fontFamily: 'Catamaran Roman Bold',
                  [theme.breakpoints.up('md')]: {
                    borderRadius: '50px',
                    height: '50px',
                    width: '200px',
                    fontSize: '22px'
                  },
                  [theme.breakpoints.down('md')]: {
                    borderRadius: '20px',
                    height: '40px',
                    width: '150px',
                    fontSize: '18px'
                  },
                  [theme.breakpoints.down('sm')]: {
                    width: '125px',
                    fontSize: '14px'
                  }
                }}
              >
                {texts('common:save')}
              </LoadingButton>
            </Grid>
          </Form>
        </Formik>
      </AuthLayout>  
    </>
  )
}

export default ResetPasswordFinish