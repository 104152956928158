import { IconButton, Paper, useTheme, MenuItem } from '@mui/material'
import { SyntheticEvent } from 'react'
import { CompanyHintDto } from '../../../api/data/types'
import MuiSearchBar from '../../customMui/SearchBar' 
import SearchIcon from '@mui/icons-material/Search'
import Typography from '@mui/material/Typography'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'

type Props = {
  visible: boolean
  showButton: boolean
  getOptionLabel: (option: string | CompanyHintDto) => string
  onInputChange: (e: React.SyntheticEvent<Element, Event>, value: string) => void
  placeholder: string
  onChange: (e: SyntheticEvent<Element, Event>, value: string | CompanyHintDto | null) => void
  options: CompanyHintDto[]
  onButtonClick: () => void
  loading?: boolean
}

function SearchBar(props: Props) {
  const theme = useTheme()
  return (
    <Paper
      elevation={0}
      sx={{
        width: 'stretch',
        background: 'transparent',
        [theme.breakpoints.up('md')]: {
          margin: '0 224px 0 148px'
        },
        [theme.breakpoints.down('md')]: {
          margin: '0 52px 0 36px'
        },
        [theme.breakpoints.down('sm')]: {
          margin: '0 0 0 8px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: props.visible ? 'stretch' : 'flex-end'
        }
      }}
    >
      {props.visible &&
        <IconButton
          onClick={props.onButtonClick}
          sx={{
            alignItems: 'center',
            display: props.showButton ? 'flex' : 'none'
          }}
        > 
          <ArrowCircleLeftIcon sx={{ color: '#3b3b3b' }}/>
        </IconButton>
      }
      <Paper
        elevation={0}
        sx={{
          background: 'transparent', 
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            marginRight: '10px'
          }
        }}
      >
        <MuiSearchBar
          sx={{
            display: props.visible ? 'block' : 'none',
            '.MuiOutlinedInput-root': {
              borderRadius: '30px !important',
              [theme.breakpoints.up('md')]: {
                height: '50px',
              },
              [theme.breakpoints.down('md')]: {
                height: '40px',
              },
              [theme.breakpoints.down('sm')]: {
                height: '40px',
              },
              alignContent: 'center',
              '.MuiOutlinedInput-input': {
                fontFamily: 'Roboto',
                [theme.breakpoints.up('md')]: {
                  fontSize: '16px'
                },
                [theme.breakpoints.down('md')]: {
                  fontSize: '14px'
                },
                [theme.breakpoints.down('sm')]: {
                  fontSize: '14px'
                }
              }
            }
          }}
          loading={props.loading}
          placeholder={props.placeholder}
          getOptionLabel={props.getOptionLabel}
          onInputChange={props.onInputChange}
          onChange={props.onChange}
          options={props.options}
          renderOption={(props, option) => (
            <MenuItem key={option.uuid} {...props} sx={{ whiteSpace: 'normal' }}>
              <Typography fontSize={{ xs: '14px', md: '16px' }}>
                {`${option.name} (CIK: ${option.cik})`}
              </Typography>
            </MenuItem>
          )}
        />
      </Paper>
      {!props.visible &&
        <IconButton
          onClick={props.onButtonClick}
          sx={{
            alignItems: 'center',
            display: props.showButton ? 'flex' : 'none'
          }}
        > 
          <SearchIcon/>
        </IconButton>
      }
    </Paper>
  )
}

export default SearchBar