import StripeApi from '../../api/stripe/StripeApi'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { StripePriceIdDto } from '../../api/stripe/types'
import { showErrorToast } from '../../utils/error'

const stripeApi = StripeApi.getInstance()

export default function useSubscribe() {
  const texts = useTranslation().t
  const mutation = useMutation((data: StripePriceIdDto) => stripeApi.subscribe(data), {
    onSuccess: (data) => {
      window.location.replace(data.sessionUrl)
    },
    onError: (error) => {
      showErrorToast(error, texts)
    },
  })
  return mutation
}