import * as Yup from 'yup'
import { codeRegex, passwordAllowedSpecialCharactersRegex, passwordRequirementsRegex, pincodeRegex } from './regex'
import { TFunction } from 'i18next'

export function signInValidation(t: TFunction) {
  return Yup.object({
    login: Yup.string()
      .email(t('validations:invalid_email_validation'))
      .required(t('validations:field_required')),
    password: Yup.string()
      .required(t('validations:field_required'))
  })
}

export function signUpValidation(t: TFunction) {
  return Yup.object({
    email: Yup.string()
      .email(t('validations:invalid_email_validation'))
      .required(t('validations:field_required')),
    password: Yup.string()
      .required(t('validations:field_required'))
      .matches(passwordAllowedSpecialCharactersRegex, t('validations:password_special_characters'))
      .min(8, t('validations:password_min_length'))
      .matches(passwordRequirementsRegex, t('validations:password_requirements'))
      .max(32, t('validations:password_max_length')),
    policyAgreed: Yup.boolean().oneOf([true], t('validations:privacy_policy_consent_required'))
  })
}

export function resetPasswordRequestValidation(t: TFunction) {
  return Yup.object({
    email: Yup.string()
      .email(t('validations:invalid_email_validation'))
      .required(t('validations:field_required'))
  })
}

export function resetPasswordVerifyValidation(t: TFunction) {
  return Yup.object({
    code: Yup.string()
      .matches(codeRegex, t('validations:pincode'))
      .required(t('validations:field_required'))
  })
}

export function resetPasswordFinishValidation(t: TFunction) {
  return Yup.object({
    password: Yup.string()
      .required(t('validations:field_required'))
      .matches(passwordAllowedSpecialCharactersRegex, t('validations:password_special_characters'))
      .min(8, t('validations:password_min_length'))
      .matches(passwordRequirementsRegex, t('validations:password_requirements'))
      .max(32, t('validations:password_max_length')),
  })
}

export function pincodeValidation(t: TFunction) {
  return Yup.object({
    code: Yup.string()
      .required(t('validations:field_required'))
      .matches(pincodeRegex, t('validations:pincode'))
  })
}