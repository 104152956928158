import { SxProps, useTheme, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import CardView from './CardView'
import { CompanyBriefInfo } from '../../../api/data/types'

type Props = {
  fiscalYearEnd?: string
  company: {
    formerName: string | null
    formerChanged: string | null
  } & CompanyBriefInfo
}

function CompanyDisplay(props: Props) {
  const texts = useTranslation().t
  const theme = useTheme()
  const cellSx: SxProps = {
    [theme.breakpoints.up('md')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '13px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    }
  }
  const rows = useMemo(() => [{
    label: texts('companies:report_address'),
    value: [props.company.address, props.company.city, props.company.region, props.company.country].filter(s => s).join(', ')
  }, {
    label: texts('companies:report_sic'),
    value: props.company.sic
  }, {
    label: texts('companies:report_cik'),
    value: props.company.cik
  }, {
    label: texts('companies:report_fiscal_end_year'),
    value: props.fiscalYearEnd ? props.fiscalYearEnd : texts('companies:missing_data_placeholder')
  }, {
    label: texts('companies:report_former'),
    value: props.company.formerName 
      ? props.company.formerChanged 
        ? texts('companies:report_former_changed', { 
          formerName: props.company.formerName,
          //date: moment(props.company.formerChanged).format('YYYY/MM/DD')
        })
        : props.company.formerName
      : texts('companies:missing_data_placeholder')
  }], [
    props.company.formerChanged,
    props.company.address,
    props.company.city,
    props.company.region,
    props.company.country,
    props.fiscalYearEnd,
    props.company.sic,
    props.company.cik,
    props.company.formerName,
    texts
  ])
  
  return (
    <CardView
      title={texts('companies:report_info_title')}
    >
      <Grid container 
        width={'100%'}
        direction={'column'}
        gap={'10px'}
        marginTop={{ xs: '-6px', md: '-10px' }}
      >
        {rows.map(r => (
          <Grid 
            container item
            key={r.label}
            direction={{ xs: 'column', sm: 'row' }}
            gap={{ xs: '4px', sm: 0 }}
          >
            <Grid 
              item
              xs={12}
              sm={4}
              md={3}
              sx={{
                ...cellSx,
                opacity: '0.5',
                fontFamily: 'Roboto Medium'
              }}
            >
              {r.label}
            </Grid>
            <Grid 
              item
              xs={12}
              sm={8}
              md={9}
              sx={{
                ...cellSx,
                fontFamily: 'Roboto'
              }}
            >
              {r.value}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </CardView>
  )
}

export default CompanyDisplay