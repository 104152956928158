import { Grid, Typography, useTheme, TextField as MuiTextField, Link, InputAdornment, Box } from '@mui/material'
import MainLayout from '../../components/layouts/MainLayout'
import { useTranslation } from 'react-i18next'
import PasswordField from '../../components/customMui/PasswordField'
import { ChangePasswordDto } from '../../api/auth/types'
import { Form, Formik, FormikHelpers } from 'formik'
import { LoadingButton } from '@mui/lab'
import { useState } from 'react'
import { useChangePassword } from '../../hooks/auth/useChangePassword'
import { AxiosError } from 'axios'
import { changePasswordValidation } from '../../utils/validations/account'
import useGenerateApiKey from '../../hooks/auth/useGenerateApiKey'
import { ApiKeyDto } from '../../types/common'
import CopyButton from '../../components/customMui/CopyButton'
import { Helmet } from 'react-helmet'

function Settings() {
  const texts = useTranslation().t
  const theme = useTheme()
  const [apiKey, setApiKey] = useState<string>('')
  const apiKeyMutation = useGenerateApiKey({
    onSuccess: (data: ApiKeyDto) => setApiKey(data.apiKey)
  })

  const changePasswordMutation = useChangePassword()

  function changePassword(data: ChangePasswordDto, formikHelpers: FormikHelpers<ChangePasswordDto>) {
    changePasswordMutation.mutate(data, {
      onError: (error) => {
        formikHelpers.setValues({
          oldPassword: '',
          newPassword: ''
        })

        if ((error as AxiosError).response?.data?.code === 100) {
          switch ((error as AxiosError).response?.data?.subcode) {
            case 106:
              formikHelpers.setFieldError('oldPassword', texts('errors:wrong_current_password'))
              break
            case 107:
              formikHelpers.setFieldError('newPassword', texts('errors:wrong_new_password'))
              break
          }
          return
        }
      },
      onSuccess: () => {
        formikHelpers.resetForm()
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>{texts('pageTitles:account_settings')}</title>
      </Helmet>
      <MainLayout>
        <Box
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
        >
          <Grid
            container
            paddingTop={{ xs: '20px', sm: '30px', md: '75px'}}
            paddingBottom={{ xs: '20px', sm: '30px', md: '75px'}}
            width={'100%'}
            maxWidth={'1252px'}
            gap={{ xs: '20px', sm: '32px', md: '50px' }}
            marginLeft={'24px'}
            marginRight={'24px'}
          >
            <Formik<ChangePasswordDto>
              onSubmit={changePassword}
              initialValues={{
                oldPassword: '',
                newPassword: ''
              }}
              validateOnChange={true}
              validateOnBlur={true}
              validationSchema={changePasswordValidation(texts)}
            >
              {(formikProps) => (
                <Form
                  style={{ width: '100%' }}
                >
                    <Grid
                      direction={'column'}
                      container 
                      item
                      width={{ xs: '100%', sm: '300px', md: '350px' }}
                      gap={{ xs: '12px', sm: '16px', md: '25px' }}
                    >

                      <Typography variant={'h3'}>
                        {texts('accounts:change_password')}
                      </Typography>
                      <PasswordField 
                        name={'oldPassword'}
                        label={texts('accounts:current_password')}
                      />
                      <PasswordField
                        label={texts('accounts:new_password')}
                        name={'newPassword'}
                      />
                      <LoadingButton
                        type={'submit'}
                        loading={changePasswordMutation.isLoading}
                        sx={{
                          textTransform: 'none',
                          borderRadius: '50px',
                          alignSelf: 'flex-end',
                          fontFamily: 'Catamaran Roman Bold',
                          [theme.breakpoints.up('md')]: {
                            height: '45px',
                            width: '150px',
                            fontSize: '20px'
                          },
                          [theme.breakpoints.down('md')]: {
                            height: '40px',
                            width: '126px',
                            fontSize: '18px'
                          },
                          [theme.breakpoints.down('sm')]: {
                            height: '34px',
                            width: '126px',
                            fontSize: '16px'
                          }
                        }}
                        variant={'contained'}
                      >
                        {texts('common:save')}
                      </LoadingButton>
                    </Grid>
                </Form>
              )}
            </Formik>

            <Grid
              direction={'column'}
              container 
              item
              width={'100%'}
              gap={{ xs: '12px', sm: '16px', md: '20px' }}
            >
              <Typography variant={'h3'}>
                {texts('accounts:generate_key')}
              </Typography>

              <Grid
                container
                item
                width={'100%'}
                gap={'inherit'}
                direction={{ xs: 'column', sm: 'row' }}
              >
                <MuiTextField
                  disabled
                  value={apiKey}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <CopyButton
                          copiedText={apiKey}
                        />
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      [theme.breakpoints.up('md')]: {
                        height: '56px',
                        width: '400px'
                      },
                      [theme.breakpoints.down('md')]: {
                        height: '44px',
                        width: '300px'
                      },
                      [theme.breakpoints.down('sm')]: {
                        height: '44px',
                        width: '100%'
                      },
                      alignContent: 'center',
                      '.MuiOutlinedInput-input': {
                        fontFamily: 'Roboto',
                        [theme.breakpoints.up('md')]: {
                          fontSize: '16px'
                        },
                        [theme.breakpoints.down('md')]: {
                          fontSize: '12px'
                        }
                      }
                    }
                  }}
                />
                <LoadingButton
                  onClick={() => apiKeyMutation.mutate()}
                  loading={apiKeyMutation.isLoading}
                  variant={'contained'}
                  sx={{
                    textTransform: 'none',
                    borderRadius: '50px',
                    [theme.breakpoints.up('md')]: {
                      height: '45px',
                      width: '150px',
                      alignSelf: 'center'
                    },
                    [theme.breakpoints.down('md')]: {
                      height: '40px',
                      width: '126px',
                      alignSelf: 'center'
                    },
                    [theme.breakpoints.down('sm')]: {
                      height: '34px',
                      width: '126px',
                      alignSelf: 'flex-end'
                    }
                  }}
                >
                  <Typography
                    fontFamily={'Catamaran Roman Bold'}
                    fontSize={{ xs: '16px', sm: '18px', md: '20px'}}
                  >
                    {texts('common:generate')}
                  </Typography>
                </LoadingButton>
              </Grid>

              <Typography
                fontFamily={'Roboto'}
                fontSize={{ xs: '12px', md: '14px' }}
                display={'inline'}
              >
                {texts('accounts:generate_key_description')}
                <Link
                  href={process.env.REACT_APP_DOC_URL!}
                  target={'_blank'}
                  display={'inline'}
                > 
                  {texts('accounts:api_documentation')}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </MainLayout>
    </>
  )
}

export default Settings