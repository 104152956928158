import { AuthSubcodes, Codes, DataSubcodes } from './consts'

export function mapHttpErrorMessage(e: any): string {
  if (e.response?.data?.code === Codes.AUTH) {
    return handleAuthErrors(e)
  } else if (e.response?.data?.code === Codes.DATA) {
    return handleDataErrors(e)
  }

  return 'errors:something_went_wrong'
}

function handleAuthErrors(e: any): string {
  let errorMessage: string = 'errors:something_went_wrong'

  switch (e.response?.data?.subcode) {
    case AuthSubcodes.CODE_ALREADY_USED:
      errorMessage = 'errors:code_already_used'
      break
    case AuthSubcodes.USER_INACTIVE:
      errorMessage = 'errors:user_inactive'
      break
    case AuthSubcodes.UNAUTHORIZED:
      errorMessage = 'errors:unauthorized'
      break
    case AuthSubcodes.ACCOUNT_REMOVED:
      errorMessage = 'errors:account_removed'
      break
    case AuthSubcodes.INCORRECT_PASSWORD:
      errorMessage = 'errors:wrong_current_password'
      break
    case AuthSubcodes.SAME_PASSWORD_AS_PREVIOUS_ONE:
      errorMessage = 'errors:same_password_as_previous_one'
      break
    case AuthSubcodes.INVALID_CREDENTIALS:
      errorMessage = 'errors:invalid_credentials'
      break
    case AuthSubcodes.INVALID_CODE:
      errorMessage = 'errors:wrong_code'
      break
    case AuthSubcodes.ONE_TIME_COOKIE_EXPIRED:
      errorMessage = 'errors:pwd_reset_cookie_expired'
      break
  }

  return errorMessage
}

function handleDataErrors(e: any): string {
  let errorMessage: string = 'errors:something_went_wrong'

  switch (e.response?.data?.subcode) {
    case DataSubcodes.EMAIL_TAKEN:
      errorMessage = 'errors:email_taken'
      break
    case DataSubcodes.BAD_REQUEST:
      errorMessage = 'errors:something_went_wrong'
      break
  }

  return errorMessage
}