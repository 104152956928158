import { Clear } from '@mui/icons-material'
import { IconButton, SxProps } from '@mui/material';

type Props = {
  onClick?: () => void
  sx?: SxProps
  iconSx?: SxProps
}

function CloseButton(props: Props) {
  return (
    <IconButton
      onClick={props.onClick}
      sx={{
        ...props.sx,
        backgroundColor: 'black',
        '&:hover': {
          backgroundColor: '#3b3b3b',
          animationDuration: '1s',
          animationTimingFunction: 'ease-in-out',
          animationDelay: '0s',
          animationIterationCount: '1',
          animationName: 'rotate',
          '@keyframes rotate': {
            '100%': { transform: 'rotate(360deg)' }
          }
        }
      }}
    >
      <Clear
        sx={props.iconSx}
        style={{ 
          color: 'white'
        }}      
      />
    </IconButton>
  )
}

export default CloseButton