import StripeApi from '../../api/stripe/StripeApi'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { queryNames } from '../queries'
import { getUserUuid } from '../../services/storage/StorageServices'
import store from '../../redux/store'
import { updateAccessStatus } from '../../redux/storageToolkit'
import { showErrorToast } from '../../utils/error'

const stripeApi = StripeApi.getInstance()

export default function useGetCurrentAccessState() {
  const texts = useTranslation().t

  const query = useQuery([queryNames.checkAccess, getUserUuid()], () => {
    return stripeApi.getCurrentAccessState()
  }, {
    cacheTime: Number.parseInt(process.env.REACT_APP_CHECK_ACTIVE_ACCESS_QUERY_CACHE_TIME!), 
    staleTime: Number.parseInt(process.env.REACT_APP_CHECK_ACTIVE_ACCESS_QUERY_CACHE_TIME!),
    onError: (error) => {
      showErrorToast(error, texts)
    },
    onSuccess: (data) => {
      store.dispatch(updateAccessStatus(data.accessStatus))
    }
  })
  return query
}