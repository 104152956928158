import { Box, Grid, Typography } from '@mui/material'
import RightArrowSrc from '../../../assets/images/RightArrow.svg'

type Props = {
  title: string
  children: JSX.Element | JSX.Element[]
}

function CardView(props: Props) {
  return (
    <Grid
      container
      boxShadow={4}
      width={'100%'}
      padding={{ xs: '10px', md: '30px' }}
      gap={{ xs: '8px', sm: '12px', md: '26px' }}
      borderRadius={{ xs: '8px', sm: '10px', md: '16px' }}
      alignItems={'center'}
      justifyContent={'flex-start'}
      sx={{ backgroundColor: '#fff' }}
    >
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
      >
        <Box
          component={'img'}
          src={RightArrowSrc}
          width={{ xs: '8px', md: '12px' }}
        />
        <Typography
          fontFamily={'Catamaran Roman Bold'}
          fontSize={{ xs: '16px', md: '18px' }}
          marginLeft={{ xs: '5px', sm: '8px', md: '10px' }}
          lineHeight={{ xs: '20px', md: '22px' }}
        >
          {props.title}
        </Typography>
      </Box>

      {props.children}
    </Grid>
  )
}

export default CardView