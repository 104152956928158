import { AuthDto } from '../../api/auth/types'
import { AccessStatus } from '../../redux/storageToolkit'

export const USER_UUID = 'user_uuid'
export const USER_EMAIL = 'user_email'
export const ACCESSED_PRODUCTS = 'accessed_products'
export const ACCESS_STATUS = 'access_status'

export function setUserData(data: AuthDto) {
  localStorage.setItem(USER_UUID, data.uuid)
  localStorage.setItem(USER_EMAIL, data.email)
  localStorage.setItem(ACCESSED_PRODUCTS, data.accessedProducts.join(';'))
  localStorage.setItem(ACCESS_STATUS, data.accessStatus)
}

export function clearUserData() {
  localStorage.removeItem(USER_UUID)
  localStorage.removeItem(USER_EMAIL)
  localStorage.removeItem(ACCESSED_PRODUCTS)
  localStorage.removeItem(ACCESS_STATUS)
}

export function setUserEmail(email: string) {
  localStorage.setItem(USER_EMAIL, email)
}

export function getUserEmail(): string | null {
  return localStorage.getItem(USER_EMAIL)
}

export function clearUserEmail() {
  localStorage.removeItem(USER_EMAIL)
}

export function setAccessStatus(accessStatus: AccessStatus) {
  localStorage.setItem(ACCESS_STATUS, accessStatus)
}

export function getUserUuid(): string | null {
  return localStorage.getItem(USER_UUID)
}

export function getAccessedProducts(): string[] {
  return localStorage.getItem(ACCESSED_PRODUCTS)?.split(';').filter(product => product) ?? []
}

export function getAccessStatus(): AccessStatus {
  return (localStorage.getItem(ACCESS_STATUS) ?? AccessStatus.INACTIVE) as AccessStatus
}

export function clearUserCache() {
  clearUserData()
}