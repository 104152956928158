import { useQuery, useQueryClient } from 'react-query';
import DataApi from '../../api/data/DataApi'
import { queryNames } from '../queries'
import { AxiosError } from 'axios'
import { getUserUuid } from '../../services/storage/StorageServices'
import { HttpError } from '../../types/common'
import { AuthSubcodes, Codes, DataSubcodes } from '../../services/errors/consts'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { showErrorToast } from '../../utils/error';

const dataApi = DataApi.getInstance()

type Options = {
  order: string
}

function isSubscriptionError(error: AxiosError<HttpError>): boolean {
  return error.response?.data.code === Codes.AUTH
    && (error.response?.data?.subcode === AuthSubcodes.SUBSCRIPTION_PAST_DUE
    || error.response?.data?.subcode === AuthSubcodes.USER_NOT_SUBSCRIBED)
}

function shouldNavigateToNotFound(error: AxiosError<HttpError>): boolean {
  return error.response?.data.code === Codes.DATA 
    && (error.response?.data?.subcode === DataSubcodes.BAD_REQUEST
    || error.response?.data?.subcode === DataSubcodes.COMPANY_NOT_FOUND)

}

export default function useFindCompany(companyUuid: string, options: Options) {
  const texts = useTranslation().t
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const query = useQuery([queryNames.companyDetails, getUserUuid(), companyUuid, options.order], () => {
    return dataApi.findCompanyByUuid(companyUuid, options.order)
  }, {
    onError: (error: AxiosError<HttpError>) => {
      if (isSubscriptionError(error)) {
        queryClient.invalidateQueries([queryNames.companiesTile, getUserUuid()])
        return
      }
      if (shouldNavigateToNotFound(error)) {
        navigate('/not-found', { replace: true })
        return
      }
      showErrorToast(error, texts)
    }
  })

  return query
}
