import { EmailDto, PasswordDto } from '../../api/auth/types'
import AuthApi from '../../api/auth/AuthApi'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { showErrorToast } from '../../utils/error'

const authApi = AuthApi.getInstance()

export default function useSignUp() {
  const texts = useTranslation().t
  const mutation = useMutation((data: EmailDto & PasswordDto) => authApi.register(data), {
    onSuccess: () => {
      toast.success(texts('successes:activation_mail_sent'))
    },
    onError: (error) => {
      showErrorToast(error, texts)
    },
  })
  return mutation
}