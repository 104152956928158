import { Autocomplete, InputAdornment, TextField as MuiTextField, TextFieldProps, SxProps } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { HTMLAttributes, ReactNode } from 'react'
import ClearIcon from '@mui/icons-material/Clear'

type Props<T> = {
  loading?: boolean
  options: T[]
  getOptionLabel?: (option: T | string) => string
  onChange?: (e: React.SyntheticEvent<Element, Event>, value: T | string | null) => void
  onInputChange?: (e: React.SyntheticEvent<Element, Event>, value: string) => void
  sx?: SxProps
  placeholder?: string
  renderOption?: (props: HTMLAttributes<HTMLLIElement>, option: T) => ReactNode | undefined
}

function SearchBar<T>(props: Props<T>) {
  return (
    <Autocomplete<T, false, false, true>
      loading={props.loading}
      sx={props.sx}
      freeSolo
      options={props.options}
      getOptionLabel={props.getOptionLabel}
      onChange={props.onChange}
      onInputChange={props.onInputChange}
      renderOption={props.renderOption}
      clearIcon={
        <ClearIcon
          sx={{
            '&:hover': {
              animationDuration: '1s',
              animationTimingFunction: 'ease-in-out',
              animationDelay: '0s',
              animationIterationCount: '1',
              animationName: 'rotate',
              '@keyframes rotate': {
                '100%': { transform: 'rotate(360deg)' }
              }
            }
          }}
        />
      }
      renderInput={(params: TextFieldProps) => (
        <MuiTextField
          {...params}
          placeholder={props.placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position={'start'}>
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      )}
    />
  )
}

export default SearchBar