import { FormControl, FormControlLabel, FormGroup, Checkbox as MuiCheckbox, CheckboxProps, SxProps, Typography, Box, FormHelperText, Grid } from '@mui/material'
import { useField, useFormikContext } from 'formik'

type Props = {
  name: string
  label?: JSX.Element
  sx?: SxProps
}

const Checkbox = (props: Props) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(props.name)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    setFieldValue(props.name, checked)
  }

  const configCheckbox: CheckboxProps = {
    ...field,
    onChange: handleChange
  }

  return (
    <FormControl 
      error={meta.touched && meta.error != null} 
      sx={props.sx}
    >
      <FormControlLabel
        label=''
        control={
          <Grid
            container
            direction={'row'}
          >
            <Grid item xs={2} sm={1.4} md={1}>
              <MuiCheckbox
                {...configCheckbox} 
                {...(
                  meta.value != null
                  ? { checked: meta.value }
                  : {}
                )}
              />
            </Grid>

            <Grid item xs={10} sm={10.6} md={11}>
              {props.label != null && props.label}
            </Grid>
          </Grid>
        }
      />
      {meta.touched && meta.error &&
        <FormHelperText>
          {meta.error}
        </FormHelperText>
      }
    </FormControl>
  )
}

export default Checkbox