import { LoadingButton } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { refreshViewImageBase64 } from '../../const'
//import imageSrc from '../../assets/images/RefreshViewImage.svg'

type Props = {
  loading?: boolean
  onClick: () => void
}

const Refresh = (props: Props) => {
  const texts = useTranslation().t

  return (
    <Box 
      display={'flex'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      flexDirection={'column'}
      gap={2}
      paddingTop={'48px'}
      marginLeft={'24px'}
      marginRight={'24px'}
      paddingBottom={'88px'}
    >
      <Box
        component={'img'}
        src={`data:image/webp;base64, ${refreshViewImageBase64}`}
        height={'auto'}
        width={{ xs: '30%', sm: '12%', lg: '10%' }}
      />
      <Typography 
        textAlign={'center'}
        textTransform={'none'}
        fontSize={{ xs: '32px', sm: '36px' }}
        fontFamily={'Catamaran Roman Bold'}
        fontWeight={800}
      >
        {texts('common:oops')}
      </Typography>
      <Typography
        fontFamily={'Catamaran Roman'}
        maxWidth={'min(80vw, 550px)'}
        fontSize={{ xs: '14px', sm: '16px' }}
        lineHeight={{ xs: '18px', sm: '20px' }}
        textAlign={'center'}
      >
        {texts('common:refresh_view_description')}
      </Typography>
      <LoadingButton
        variant={'contained'}
        size={'medium'}
        loading={props.loading}
        onClick={props.onClick}
        sx={{
          height: '45px',
          width: '150px',
          borderRadius: '50px',
          px: '1rem',
          textTransform: 'none',
          fontFamily: 'Catamaran Roman Bold',
          fontSize: '18px'
        }}
      >
        {texts('common:refresh_view_button')}
      </LoadingButton>
    </Box>
  )
}

export default Refresh