import { Success, TokenDto } from '../../types/common'
import { Api } from '../Api'
import { AccessStateDto } from '../auth/types'
import { SessionUrlDto, StripePriceIdDto } from './types'

class StripeApi extends Api {
  private constructor() {
    super({
      baseURL: `${process.env.REACT_APP_BASE_DATA}/api/v1/data/stripe/`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
  private static instance: StripeApi

  static getInstance(): StripeApi {
    if (StripeApi.instance) {
      return this.instance
    }
    return this.instance = new StripeApi()
  }

  public subscribe(data: StripePriceIdDto): Promise<SessionUrlDto> {
    return this.post<SessionUrlDto>(`subscribe/${data.priceId}`)
  }

  public viewBillings(): Promise<SessionUrlDto> {
    return this.get<SessionUrlDto>(`billings/view`)
  }

  public getCurrentAccessState(): Promise<AccessStateDto> {
    return this.get<AccessStateDto>('/access/current')
  }
}

export default StripeApi