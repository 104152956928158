import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { SearchOptionsDto } from '../../api/data/types'
import DataApi from '../../api/data/DataApi'
import { queryNames } from '../queries'
import { getUserUuid } from '../../services/storage/StorageServices'
import { AuthSubcodes, Codes } from '../../services/errors/consts'
import { AxiosError } from 'axios'
import { HttpError } from '../../types/common'
import store from '../../redux/store';
import { AccessStatus, RedirectState, updateAccessStatus, updateRedirectState } from '../../redux/storageToolkit'
import { showErrorToast } from '../../utils/error'

const dataApi = DataApi.getInstance()

type Options = {
  enabled?: boolean
}

function isSubscriptionError(error: AxiosError<HttpError>): boolean {
  return error.response?.data.code === Codes.AUTH
    && (error.response?.data?.subcode === AuthSubcodes.SUBSCRIPTION_PAST_DUE
    || error.response?.data?.subcode === AuthSubcodes.USER_NOT_SUBSCRIBED)
}

export default function useSearchCompanies(searchOptions: SearchOptionsDto, options?: Options) {
  const texts = useTranslation().t

  const query = useQuery([queryNames.companiesTile, getUserUuid(), searchOptions.offset, searchOptions.search], () => {
    return dataApi.searchCompanies(searchOptions)
  }, {
    onSuccess: (data) => {
      store.dispatch(updateAccessStatus(data.accessStatus))
      if (data.accessStatus === AccessStatus.PAST_DUE) {
        store.dispatch(updateRedirectState(RedirectState.RESUBSCRIBE))
      }
    },
    onError: (error: AxiosError<HttpError>) => {
      if (isSubscriptionError(error)) {
        return
      }

      showErrorToast(error, texts)
    },
    ...options
  })

  return query
}
