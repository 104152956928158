import { ChangePasswordDto } from '../../api/auth/types'
import AuthApi from '../../api/auth/AuthApi'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { showErrorToast } from '../../utils/error'

const authApi = AuthApi.getInstance()

export function useChangePassword() {
  const texts = useTranslation().t

  const mutation = useMutation((data: ChangePasswordDto) => authApi.changePassword(data), {
    onSuccess: () => {
      toast.success(texts('successes:password_changed'))
    },
    onError: (error) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}