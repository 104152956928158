import { CodeDto, EmailDto } from '../../api/auth/types'
import AuthApi from '../../api/auth/AuthApi'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import store from '../../redux/store'
import { logIn } from '../../redux/storageToolkit'
import { showErrorToast } from '../../utils/error'

const authApi = AuthApi.getInstance()

export function useActivateUser() {
  const texts = useTranslation().t
  const mutation = useMutation((data: EmailDto & CodeDto) => authApi.activateUser(data), {
    onSuccess: (data) => {
      toast.success(texts('successes:email_activated'))
      store.dispatch(logIn(data))
    },
    onError: (error) => {
      showErrorToast(error, texts)
    },
  })
  return mutation
}