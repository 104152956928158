import { Box, Grid, Paper, SxProps, Typography, useTheme } from '@mui/material'
import { LoremIpsum } from 'lorem-ipsum'
import { useMemo, useRef } from 'react'
import { CompanyTileDto } from '../../../api/data/types'
import imageSrc from '../../../assets/images/CompanyTileImage.svg'
import LockIcon from '@mui/icons-material/Lock'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

type Props = {
  onLockedTileClick: () => void
  directToUrl: (company: CompanyTileDto) => string
  company?: CompanyTileDto
  sx?: SxProps
}

function CompanyTile(props: Props) {
  const theme = useTheme()
  const texts = useTranslation().t
  const ref = useRef<HTMLDivElement | null>(null)

  const displayedInfo = useMemo(() => {
    const loremIpsum = new LoremIpsum()
    
    return props.company
      ? {
        name: props.company.info.name,
        info: [props.company.info.address, props.company.info.city, props.company.info.region, props.company.info.country].filter(s => s).join(', '),
        cik: `CIK: ${props.company.info.cik}`,
        sic: `SIC: ${props.company.info.sic}`
      } : {
        name: loremIpsum.generateWords(3),
        info: loremIpsum.generateWords(5),
        cik: loremIpsum.generateWords(2),
        sic: loremIpsum.generateWords(2)
      }
    }, [props.company])

  return (
    <Paper
      sx={{
        backgroundColor: '#fff',
        borderRadius: '15px',
        boxShadow: 4,
        position: 'relative',
        height: 'fit-content'
      }}
    >
      <Link
        style={{
          color: 'black',
          textDecoration: 'none'
        }}
        to={props.company ? props.directToUrl(props.company) : ''}
        onClick={props.company ? undefined : props.onLockedTileClick}
      >
        <Grid
          ref={ref}
          container
          direction={'column'}
          gap={1}
          alignContent={'flex-start'}
          sx={{
            filter: props.company ? 'none' : 'blur(8px)',
            position: props.company ? 'static' : 'absolute',
            backgroundImage: `url(${imageSrc})`,
            backgroundRepeat: 'no-repeat',
            [theme.breakpoints.up('md')]: {
              backgroundSize: '48px',
              backgroundPositionX: '20px',
              backgroundPositionY: '20px',
              padding: '20px 20px 20px 83px'
            },
            [theme.breakpoints.down('md')]: {
              backgroundSize: '32px',
              backgroundPositionX: '20px',
              backgroundPositionY: '15px',
              padding: '15px 20px 15px 63px'
            },
            [theme.breakpoints.down('sm')]: {
              backgroundSize: '32px',
              backgroundPositionX: '10px',
              backgroundPositionY: '10px',
              padding: '10px 10px 10px 50px'
            },
            '.MuiTypography-root': {
              width: 'fit-content',
              textAlign: 'left'
            }
          }}
          >
          <Typography 
            fontSize={{ xs: '18px', md: '24px'}}
            fontFamily={'Catamaran Roman Bold'}
            textTransform={'uppercase'}
          >
            {displayedInfo.name}
          </Typography>
            <Typography 
              fontSize={{ xs: '12px', md: '16px'}}
              fontFamily={'Roboto'}
              textTransform={'uppercase'}
              lineHeight={'16px'}
              sx={{
                overflowWrap: 'anywhere'
              }}
            >
              {displayedInfo.info}
            </Typography>

          <Box
            display={'flex'}
            flexDirection={'row'}
          >
            <Typography 
              bgcolor={'#E8E8E8'}
              fontFamily={'Roboto'}
              fontSize={{ xs: '12px', md: '16px' }}
              borderRadius={'5px'}
              paddingLeft={'3px'}
              paddingRight={'3px'}
            >
              {displayedInfo.sic}
            </Typography>

            <Typography 
              bgcolor={'#E8E8E8'}
              fontFamily={'Roboto'}
              fontSize={{ xs: '12px', md: '16px' }}
              marginLeft={'12px'}
              borderRadius={'5px'}
              paddingLeft={'3px'}
              paddingRight={'3px'}
            >
              {displayedInfo.cik}
            </Typography>
          </Box>
        </Grid>
        {props.company == null && 
          <Box
            zIndex={1}
            width={'100%'}
            display={'flex'}
            justifyContent={'center'}
            flexDirection={'column'}
            alignItems={'center'}
            sx={{
              height: `calc(${ref.current?.offsetHeight ?? 128}px)`
            }}
          >
            <LockIcon 
              sx={{
                [theme.breakpoints.up('md')]: {
                  height: '36px',
                  width: '27px'
                },
                [theme.breakpoints.down('md')]: {
                  height: '24px',
                  width: '18px'
                }
              }}
              />
            <Typography 
              fontSize={'12px'} 
              fontFamily={'Roboto'}
            >
              {texts('companies:blocked_content_description')}
            </Typography>
          </Box>
        }
      </Link>
    </Paper>
  )
}

export default CompanyTile