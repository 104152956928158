import { Grid, Paper, useTheme } from '@mui/material'
import backgroundImage from '../../assets/images/AuthBackground.webp'
import MainLayout from './MainLayout'

type Props = {
  children?: JSX.Element
}

function AuthLayout(props: Props) {
  const theme = useTheme()

  return (
    <MainLayout
      sx={{
        [theme.breakpoints.up('md')]: {
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'min(calc(100vw - 48px), 1300px) contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center 122px'
        },
        [theme.breakpoints.down('md')]: {
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'min(calc(100vw - 48px), 800px)',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center 122px'
        },
        [theme.breakpoints.down('sm')]: {
          backgroundImage: 'none'
        }
      }}
    >
      <Grid
        component={'div'}
        container
        direction={'column'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        marginTop={5}
        marginBottom={'50px'}
      >
        <Paper
          elevation={4}
          sx={{
            backgroundColor: 'white',
            borderRadius: '20px',
            [theme.breakpoints.up('md')]: {
              width: '450px',
              padding: 5
            },
            [theme.breakpoints.up('sm')]: {
              width: '360px',
              padding: 4
            },
            [theme.breakpoints.down('sm')]: {
              width: '266px',
              padding: '16px'
            }
          }}
        >
          { props.children }
        </Paper>
      </Grid>
    </MainLayout>
  )
}
export default AuthLayout