import companiesImageSrc from '../../../assets/images/CompaniesImage.webp'
import { Grid, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { SearchDto } from '../../../api/data/types'
import FormikSearchBar from './FormikSearchBar'
import { Box } from '@mui/system';

type Props = {
  onSearchClick: (data: SearchDto) => void
  searchBarDisabled: boolean
  searchValue?: string
  onClearIconClick: () => void
}

function CompanyListTopSection(props: Props) {
  const texts = useTranslation().t

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        width: 'stretch',
        maxWidth: '1252px',
        marginLeft: '24px',
        marginRight: '24px',
      }}
    >  
      <Grid
        container
        direction={'row'}
        width={{ xs: '100%'}}
        gap={'35px'}
      >
        <Grid
          container item
          gap={{ xs: '10px', sm: '20px', md: '30px' }}
          maxWidth={{ xs: '100%', sm: '426px', md: '650px' }}
          height={{ sm: '200px', md: '360px' }}
          xs={12} sm={6}
        >
          <Box component={'span'}>
            <Typography
              fontFamily={'Catamaran Roman Bold'}
              fontSize={{ xs: '24px', sm: '36px', md: '64px' }}
              lineHeight={{ xs: '24px', sm: '36px', md: '80px' }}
            >
              {texts('companies:companies_list_title_line1')}
            </Typography>
            <Typography
              fontFamily={'Catamaran Roman Bold'}
              fontSize={{ xs: '24px', sm: '36px', md: '64px' }}
              lineHeight={{ xs: '24px', sm: '36px', md: '80px' }}
            >
              {texts('companies:companies_list_title_line2')}
            </Typography>
          </Box>
          <Typography
            fontFamily={'Roboto'}
            fontSize={{ xs: '12px', md: '20px' }}
            lineHeight={{ xs: '16px', md: '24px' }}
            sx={{ opacity: '50%' }}
            maxWidth={'650px'}
          >
            {texts('companies:companies_list_subtitle')}
          </Typography>
          <Formik<SearchDto>
            onSubmit={props.onSearchClick}
            enableReinitialize
            initialValues={{
              search: props.searchValue
            }}
          >
            {(formikProps) => (
              <Form style={{ width: '100%' }}>
                <FormikSearchBar
                  disabled={props.searchBarDisabled}
                  name={'search'}
                  formikProps={formikProps}
                  onInputChange={(value) => formikProps.setFieldValue('search', value)}
                  onClearIconClick={() => {
                    formikProps.setFieldValue('search', undefined)
                    props.onClearIconClick()
                  }}
                />
              </Form>
            )}
          </Formik>      
        </Grid>
        <Grid
          container item
          display={{ xs: 'none', sm: 'flex' }}
          direction={'column'}
          height={'100%'}
          justifyContent={'flex-end'}
          xs={0} sm={5}
        >
          <Box
            component={'img'}
            src={companiesImageSrc}
            width={'100%'}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default CompanyListTopSection