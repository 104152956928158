import MainLayout from '../../components/layouts/MainLayout'
import NotFound from '../../components/common/NotFound'

const NotFoundPage = () => {
  return (
    <MainLayout>
      <NotFound />
    </MainLayout>
  )
}

export default NotFoundPage