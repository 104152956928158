import { Form, Formik, FormikHelpers } from 'formik'
import { CodeDto, EmailDto } from '../../api/auth/types'
import AuthLayout from '../../components/layouts/AuthLayout'
import { useTranslation } from 'react-i18next'
import { Grid, Typography, useTheme, Button } from '@mui/material'
import { Box } from '@mui/system'
import { LoadingButton } from '@mui/lab'
import { useLocation, useNavigate } from 'react-router-dom'
import Pincode from '../../components/customReact/Pincode'
import { useCheckToken, useSendResetPasswordRequest } from '../../hooks/auth/useResetPassword'
import toast from 'react-hot-toast'
import { resetPasswordVerifyValidation } from '../../utils/validations/auth'
import { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'


function ResetPasswordVerify() {
  const theme = useTheme()
  const texts = useTranslation().t
  const navigate = useNavigate()
  const location = useLocation()
  const resendMutation = useSendResetPasswordRequest({ resent: true })
  const verificationMutation = useCheckToken()
  const ref = useRef<HTMLInputElement[]>(null)

  useEffect(() => {
    // tutaj niby trzebaby było wyslac request z potwierdzeniem ze przegadarka ma pwd_reset_cookie
    // ale state.email jest ustawiany tylko w onSuccess w którym też otrzymuje to cookie
    if (!(location.state as EmailDto | null)?.email) {
      navigate('/login', { replace: true })
    }
  }, [location.state, navigate])

  function handleSubmit(data: CodeDto, formikHelpers: FormikHelpers<CodeDto>) {
    verificationMutation.mutate({
      email: (location.state as EmailDto).email,
      code: data.code
    }, {
      onSuccess() {
        navigate('/password/reset/finish', {
          replace: true,
          state: {
            enteredFromApp: true
          }
        })
      },
      onError: () => {
        formikHelpers.setFieldValue('code', '')
        ref.current?.forEach(input => {
          input.value = ''
        })
        ref.current?.[0].focus()
      }
    })
  }

  function resendVerificationEmail() {
    if (!location.state || !(location.state as EmailDto).email) {
      toast.error(texts('errors:something_went_wrong'))
      return  
    }

    resendMutation.mutate({
      email: (location.state as EmailDto).email
    })
  }

  return (
    <>
      <Helmet>
        <title>{texts('pageTitles:reset_password_verify')}</title>
      </Helmet>
      <AuthLayout>
        <Formik<CodeDto>
          initialValues={{
            code: ''
          }}
          onSubmit={handleSubmit}
          validationSchema={resetPasswordVerifyValidation(texts)}
          validateOnChange={true}
          validateOnBlur={true}
        >
          <Form>
            <Grid
              gap={{ xs: 2, sm: 3, md: 4 }}
              container
              direction={'column'}
              alignItems={'center'}
              width={'100%'}
            >
              <Typography variant={'subtitle1'}>{texts('auth:verification_title')}</Typography>
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  [theme.breakpoints.up('md')]: {
                    lineHeight: '20px',
                    fontSize: '16px'
                  },
                  [theme.breakpoints.down('md')]: {
                    lineHeight: '18px',
                    fontSize: '14px'
                  }
                }}
              >
                {texts('auth:verification_subtitle')}
              </Typography>
              <Pincode name={'code'} ref={ref}/>
              <LoadingButton
                type='submit'
                variant={'contained'}
                sx={{
                  backgroundColor: '#6236FF',
                  '&:hover': {
                    backgroundColor: '#3A2099'
                  },
                  boxShadow: 8,
                  textTransform: 'none',
                  fontFamily: 'Catamaran Roman Bold',
                  [theme.breakpoints.up('md')]: {
                    borderRadius: '50px',
                    height: '50px',
                    width: '200px',
                    fontSize: '22px'
                  },
                  [theme.breakpoints.down('md')]: {
                    borderRadius: '20px',
                    height: '40px',
                    width: '150px',
                    fontSize: '18px'
                  },
                  [theme.breakpoints.down('sm')]: {
                    width: '125px',
                    fontSize: '14px'
                  }
                }}
              >
                {texts('auth:verify')}
              </LoadingButton>

              <Box component={'span'}>
                <Typography
                  fontFamily={'Roboto'}
                  sx={{
                    fontFamily: 'Roboto',
                    [theme.breakpoints.up('md')]: {
                      lineHeight: '20px',
                      fontSize: '16px'
                    },
                    [theme.breakpoints.down('md')]: {
                      lineHeight: '18px',
                      fontSize: '14px'
                    }
                  }}
                  fontWeight={400}
                  color={'#404040'}
                  display={'inline'}
                >
                  {texts('auth:resend_description')}
                </Typography>

                <Button
                  onClick={resendVerificationEmail}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  variant={'outlined'}
                  sx={{
                    backgroundColor: 'white',
                    textTransform: 'none',
                    border: 'none',
                    outline: 'none',
                    padding: 0,
                    width: 'fit-content',
                    [theme.breakpoints.up('sm')]: {
                      marginLeft: '8px'
                    },
                    ':hover': {
                      border: 'none',
                      backgroundColor: 'white'
                    }
                  }}
                >
                  <Typography 
                    sx={{
                      fontFamily: 'Roboto Medium',
                      fontWeight: 400,
                      [theme.breakpoints.up('md')]: {
                        fontSize: '16px'
                      },
                      [theme.breakpoints.down('md')]: {
                        fontSize: '14px'
                      }
                    }}
                  >
                    {texts('auth:resend')}
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Form>
        </Formik>
      </AuthLayout>  
    </>
  )
}

export default ResetPasswordVerify