import { FormControl, FormHelperText, Grid, useTheme } from '@mui/material'
import { useField } from 'formik'
import PinField from 'react-pin-field'
import * as React from 'react'

type Props = {
  name: string
}

const Pincode = React.forwardRef((props: Props, ref: React.ForwardedRef<HTMLInputElement[]>) => {
  const theme = useTheme()
  const [field, meta, helpers] = useField(props.name)

  function handleChange(code: string) {
    helpers.setValue(code)
  }

  return (
    <FormControl
      error={meta.touched && meta.error != null}
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Grid 
        container
        flexDirection={'row'}
        width={'100%'}
        gap={{ xs: '4px', sm: '6px' }}
        justifyContent={'center'}
        sx={{
          'input': {
            [theme.breakpoints.up('sm')]: {
              width: '40px',
              height: '50px',
              '&:nth-of-type(3)': {
                marginRight: '4px'
              },
              '&:nth-of-type(4)': {
                marginLeft: '4px',
              }
            },
            [theme.breakpoints.down('sm')]: {
              width: '32px',
              height: '40px',
              '&:nth-of-type(3)': {
                marginRight: '2px'
              },
              '&:nth-of-type(4)': {
                marginLeft: '2px',
              }
            }
          }
        }}
      > 
        <PinField
          validate={/[0-9]/}
          pattern='[0-9]*'
          inputMode='numeric'
          length={6}
          ref={ref}
          style={{
            accentColor: meta.touched && meta.error ? 'red' : theme.palette.primary.main,
            //width: '40px',
            //height: '50px',
            borderStyle: 'solid',
            borderRadius: '4px',
            borderColor: meta.touched && meta.error ? '#d32f2f' : theme.palette.secondary.main,
            textAlign: 'center',
            fontSize: '24px', 

          }}
          onChange={handleChange}
        />
        </Grid>
        {meta.touched && meta.error &&
          <FormHelperText>
            {meta.error}
          </FormHelperText>
        }
    </FormControl>
  )
})

export default Pincode