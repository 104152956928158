import { Box, Grid, Typography, useTheme } from '@mui/material'

type Props = {
  display?: boolean
  labels: {
    color: string,
    name: string
  }[]
}

function Legend(props: Props) {
  const theme = useTheme()
  
  return (
    <Grid
      container
      direction={'row'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={{ xs: '6px', sm: '10px', md: '13px' }}
      display={props.display !== false ? 'flex' : 'none'}
    >
      {
        props.labels.map(label => (
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
          >
            <Box 
              component={'span'}
              sx={{
                display: 'block',
                backgroundColor: label.color,
                borderRadius: '50%',
                [theme.breakpoints.up('md')]: {
                  height: '18px',
                  width: '18px',
                  marginLeft: '16px'
                },
                [theme.breakpoints.down('md')]: {
                  height: '14px',
                  width: '14px',
                  marginLeft: '20px'
                },
                [theme.breakpoints.down('sm')]: {
                  height: '10px',
                  width: '10px',
                  marginLeft: '13px'
                }
              }}
            />
          
            <Typography
              fontStyle={'Roboto'}
              fontSize={{ xs: '10px', sm: '12px', md: '14px' }}
              marginLeft={'8px'}
            >
              {label.name}
            </Typography>
          </Box>
        ))
      }
    </Grid>
  )
}

export default Legend