import { Paper, useTheme } from '@mui/material'
import { Bar } from 'react-chartjs-2'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CardView from './CardView'
import Legend from '../../chart/Legend'
import { 
  ChartData, 
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
 } from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
)

type Props = {
  windowWidth: number
  values: {
    focusYear: number
    chiSquared: number
    spMedian: number | null
  }[]
}

function FormerYearsChart(props: Props) {
  const theme = useTheme()
  const texts = useTranslation().t

  const data: ChartData<'bar', (number | null)[]> = useMemo(() => {
    const properties = {
      xLabels: [] as number[],
      values: [] as number[],
      spMedians: [] as (number | null)[]
    }

    for (const value of props.values) {
      properties.xLabels.push(value.focusYear)
      properties.values.push(value.chiSquared)
      properties.spMedians.push(value.spMedian)
    }

    return {
      labels: properties.xLabels,
      datasets: [{
        categoryPercentage: props.windowWidth < theme.breakpoints.values.sm ? 0.8 : 0.6,
        label: texts('companies:former_years_values'),
        data: properties.values,
        backgroundColor: '#FFA121',
        borderRadius: props.windowWidth < theme.breakpoints.values.sm ? 2 : 4
      }, {
        categoryPercentage: props.windowWidth < theme.breakpoints.values.sm ? 0.8 : 0.6,
        label: texts('companies:former_years_median_values'),
        data: properties.spMedians,
        backgroundColor: '#FFD090',
        borderRadius: props.windowWidth < theme.breakpoints.values.sm ? 2 : 4,
        barThickness: 'flex'
      }]
    }
  }, [props.values, props.windowWidth, theme.breakpoints.values.sm, texts])
  
  const options = useMemo(() => 
    props.windowWidth < theme.breakpoints.values.sm
      ? {
          scales: {
            yAxes: {
              ticks: {
                maxRotation: 0,
                minRotation: 0,
                font: {
                  size: 9
                }
              }
            },
            xAxes: {
              ticks: {
                maxRotation: 0,
                minRotation: 0,
                font: {
                  size: 9
                }
              }
            }
          }
        }
      : {
          scales: {
            yAxes: {
              ticks: {
                maxRotation: 0,
                minRotation: 0,
                font: {
                  size: 12
                }
              }
            },
            xAxes: {
              ticks: {
                maxRotation: 0,
                minRotation: 0,
                font: {
                  size: 12
                }
              }
            }
          }
        },
    [props.windowWidth, theme.breakpoints.values.sm]
  )

  return (
    <CardView
      title={texts('companies:former_years_chart_title')}
    >
      <Paper
        elevation={0}
        sx={{
          width: '100%',
          [theme.breakpoints.up('md')]: {
            height: '210px'
          },
          [theme.breakpoints.down('md')]: {
            height: '178px'
          },
          paddingBottom: '10px'
        }}
      >
        <Bar
          data={data}
          options={{
            ...options,
            normalized: true,
            plugins: {
              tooltip: {
                callbacks: {
                  label: (item) => `${item.dataset.label}: ${Number.parseFloat(item.formattedValue.replace(',', '.')).toFixed(2)}`
                }
              },
              legend: {
                display: false
              }
            },
            maintainAspectRatio: false
          }}
        />
      </Paper>
      <Legend
        display={props.values.some(v => v.spMedian != null)}
        labels={[{
          color: '#FFA121',
          name: texts('companies:former_years_values')
        }, {
          color: '#FFD090',
          name: texts('companies:former_years_median_values')
        }]}
      />
    </CardView>
  )
}

export default FormerYearsChart