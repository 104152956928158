import { AccountCircle } from '@mui/icons-material'
import { Box, IconButton, Menu, MenuItem, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useLogout from '../../hooks/auth/useLogout'
import { getUserEmail } from '../../services/storage/StorageServices'
import { Divider } from '@mui/material'

function AccountNav() {
  const texts = useTranslation().t
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const logoutMutation = useLogout()

  function handleOpen(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }
  function handleClose() {
    setAnchorEl(null)
  }
  function handleSettings() {
    navigate('/account')
  }
  function handleSearchForCompanies() {
    navigate('/')
  }
  function handleLogout() {
    logoutMutation.mutate()
  }

  return (
    <Box>
      <IconButton
        disableRipple
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 'fit-content'
        }}
      > 
        <AccountCircle style={{ color: 'black' }}/>
        <Typography
          marginLeft={{ xs: 0, sm: '5px' }}
          fontFamily={'Catamaran Roman Bold'}
          fontSize={{ xs: '0', sm: '16px', md: '18px'}}
          color={'#404040'}
          noWrap
        >
          {texts('accounts:my_account')}
        </Typography>
        <ArrowDropDownIcon style={{ color: 'black' }}/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom'
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        sx={{
          '& .MuiPaper-root': {
            padding: '13px',
            boxShadow: 6,
            borderRadius: '10px'
          },
          '& .MuiList-root': {
            padding: 0
          },
          '& .MuiMenuItem-root': {
            padding: '7px',
            fontSize: '16px',
            width: '100%'
          }
        }}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          sx={{
            pointerEvents: 'none',
            ':hover': {
              cursor: 'default',
              backgroundColor: 'white'
            },
            fontStyle: 'Roboto Light',
            color: '#404040',
          }}
        >
          {getUserEmail()}
        </MenuItem>
        <Divider sx={{ width: '100%', borderColor: '#979797', opacity: 0.5 }}/>
        <MenuItem 
          onClick={handleSearchForCompanies} 
          sx={{ 
            fontWeight: 600,
           }}
        >
          {texts('accounts:search_for_companies')}
        </MenuItem>
        <MenuItem 
          onClick={handleSettings} 
          sx={{ 
            fontWeight: 600,
          }}
        >
          {texts('accounts:settings')}
        </MenuItem>
        <MenuItem 
          onClick={handleLogout} 
          sx={{ 
            fontWeight: 600,
           }}
        >
          {texts('accounts:logout')}
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default AccountNav