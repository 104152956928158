import { Box, Grid, Typography } from '@mui/material'
import emptyListImageSrc from '../../../assets/images/EmptyListImage.svg'
import { useTranslation } from 'react-i18next'

function EmptyListPLaceHolder() {
  const texts = useTranslation().t

  return (
    <Grid
      width={'100%'}
      container
      direction={'column'}
      alignContent={'center'}
      gap={1}
    >
      <Box
        alignSelf={'center'}
        component={'img'}
        src={emptyListImageSrc}
        height={'64px'}
        width={'64px'}
      />
      <Typography fontSize={'18px'}>{texts('companies:no_data_found')}</Typography>
    </Grid>
  )
}

export default EmptyListPLaceHolder