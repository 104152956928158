import { Box, Grid, Typography } from '@mui/material'
import imageSrc from '../../assets/images/NotFoundImage.webp'
import { useTranslation } from 'react-i18next'

type Props = {
  text?: string
  color?: string
  backgroundColor?: string
  width?: string
  height?: string
  size?: number
}

const NotFound = (props: Props) => {
  const texts = useTranslation().t

  return (
    <Grid
      container
      width={props.width ?? '100%'}
      height={props.height ?? '100%'}
      alignItems={'center'}
      justifyContent={'center'}
      direction={'column'}
      gap={2}
      padding={'5%'}
    >
      <Box
        component={'img'}
        src={imageSrc}
        height={'auto'}
        width={{ xs: '40%', lg: '30%' }}
      />
      <Typography 
        fontFamily={'Catamaran Roman Bold'} 
        fontSize={{ xs: '32px', sm: '36px' }}
        fontWeight={800}
      >
        {texts('common:oops')}
      </Typography>
      <Typography 
        fontFamily={'Catamaran Roman'}
        textAlign={'center'}
        fontSize={{ xs: '14px', sm: '16px' }}
        lineHeight={{ xs: '18px', sm: '20px' }}
      >
        {texts('errors:page_not_found_subtitle')}
      </Typography>
    </Grid>
  )
}

export default NotFound