import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import AuthApi from '../../api/auth/AuthApi'
import { ApiKeyDto } from '../../types/common'
import { showErrorToast } from '../../utils/error'

type Options = {
  onSuccess: (data: ApiKeyDto) => void
}

const authApi = AuthApi.getInstance()

export default function useGenerateApiKey(options: Options) {
  const texts = useTranslation().t
  const mutation = useMutation(() => authApi.generateApiKey(), {
    ...options,
    onError: (error) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}