import { TFunction } from 'i18next'
import * as Yup from 'yup'
import { passwordAllowedSpecialCharactersRegex, passwordRequirementsRegex } from './regex'

export function changePasswordValidation(t: TFunction) {
  return Yup.object({
    oldPassword: Yup.string()
      .required(t('validations:field_required')),
    newPassword: Yup.string()
      .required(t('validations:field_required'))
      .matches(passwordAllowedSpecialCharactersRegex, t('validations:password_special_characters'))
      .min(8, t('validations:password_min_length'))
      .matches(passwordRequirementsRegex, t('validations:password_requirements'))
      .max(32, t('validations:password_max_length')),
  })
}