import AuthLayout from '../../components/layouts/AuthLayout'
import { useTranslation } from 'react-i18next'
import { Link, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import useSubscribe from '../../hooks/stripe/useSubscribe'
import SubscribeNow from '../../components/subscriptions/SubscribeNow'
import { Helmet } from 'react-helmet'


function Subscribe() {
  const texts = useTranslation().t
  const mutation = useSubscribe()
  const theme = useTheme()

  return (
    <>
      <Helmet>
        <title>{texts('pageTitles:subscribe')}</title>
      </Helmet>
      <AuthLayout>
        <SubscribeNow
          title={texts('auth:subscribe_title')}
          subtitle={texts('auth:subscribe_subtitle')}
          onClick={() => mutation.mutate({
            priceId: process.env.REACT_APP_PRODUCT_PRICE_ID!
          })}
          isLoading={mutation.isLoading}
          displayedPrice={texts('auth:subscribe_display_price')}
          bottomSection={
            <Box component={'span'}>
              <Typography 
                fontFamily={'Roboto'}
                sx={{
                  fontFamily: 'Roboto',
                  [theme.breakpoints.up('md')]: {
                    lineHeight: '20px',
                    fontSize: '16px'
                  },
                  [theme.breakpoints.down('md')]: {
                    lineHeight: '18px',
                    fontSize: '14px'
                  }
                }}
                fontWeight={400}
                color={'#404040'}
                display={'inline'}
              >
                {texts('auth:subscribe_skip_description')}
              </Typography>
      
              <Link
                paddingLeft={{ xs: 0.5 }}
                href='/'
                underline={'none'}
                variant={'h4'}
              >
                {texts('auth:subscribe_skip')}
              </Link>
            </Box>
          }
        />
      </AuthLayout>  
    </>
  )
}

export default Subscribe