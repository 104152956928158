import { BottomNavigation, BottomNavigationAction, Box, Grid, Link, responsiveFontSizes, SxProps, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import whiteLogoSrc from '../../assets/images/LogoWhite.svg'
import moment from 'moment-timezone'

type Props = {
  sx?: SxProps
}

function Footer(props: Props) {
  const texts = useTranslation().t
  const theme = responsiveFontSizes(useTheme())

  const linkSx: SxProps = {
    fontFamily: 'Roboto Light',
    fontWeight: 400,
    opacity: '75%',
    marginTop: '2px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.625rem',
    }
  }

  return (
    <Box sx={props.sx}>
      <BottomNavigation
        sx={{
          width: '100%',
          height: '400px'
        }}
      >
        <Grid 
          container 
          paddingTop={'20px'}
          paddingBottom={'20px'}
          paddingLeft={{ xs: '8px', md: '7.5rem' }}
          paddingRight={{ xs: '8px', md: '7.5rem' }}
          width={'100%'}
          maxWidth={'1252px'}
          gap={'1.25rem'}
        >
          <Grid item xs={12} sm={5.8}> 
            <Box
              component={'img'}
              src={whiteLogoSrc}
              alt={'White logo'}
              sx={{
                [theme.breakpoints.up('md')]: {
                  marginLeft: '-22px',
                  height: '32px',
                  width: '216px'
                },
                [theme.breakpoints.up('sm')]: {
                  marginLeft: '14px',
                  height: '24px',
                  width: '162px'
                },
                [theme.breakpoints.down('sm')]: {
                  marginLeft: '14px',
                  height: '20px',
                  width: '134px'
                }
              }}
            />
          </Grid>
          <Grid container item xs={12} sm={5}
            display={'flex'}
            flexDirection={'column'}
            gap={'0.5rem'}
            sx={{
              [theme.breakpoints.down('sm')]: {
                marginLeft: '15px',
              }
            }}
          >
            <Typography 
              fontSize={{ xs: '0.75rem', sm: '1.125rem', md: '1.5rem' }}
              fontFamily={'Catamaran Roman Bold'}
              color={'white'}
            >
              { texts('common:footer_contact_description') }
            </Typography>
            <BottomNavigationAction
              disableRipple
              label={texts('common:footer_contact')}
              icon={
                <EmailOutlinedIcon
                  sx={{
                    color: 'white',
                    [theme.breakpoints.up('md')]: {
                      height: '18px',
                      width: '18px'
                    },
                    [theme.breakpoints.down('md')]: {
                      height: '18px',
                      width: '18px'
                    }
                  }}
                />
              } 
              showLabel
            />
          </Grid>
        </Grid>
      </BottomNavigation>

      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        width={'stretch'}
        maxWidth={'1252px'}
        alignItems={'center'}
        sx={{
          marginBottom: '0px',
          backgroundColor: 'white',
          height: '48px',
          [theme.breakpoints.up('sm')]: {
            marginLeft: '24px',
            marginRight: '24px'
          },
          [theme.breakpoints.down('sm')]: {
            marginLeft: '1rem',
            marginRight: '1rem'
          }
        }}
      >
        <Grid
          container
          direction={'row'}
        >
          <Grid 
            container item
            paddingTop={{ xs: '0.5rem', sm: 0 }}
            justifyContent={{ xs: 'center', sm: 'flex-start' }}
            justifyItems={'center'}
            xs={12}
            sm={6}
            order={{ xs: 2, sm: 1 }}
          >
            <Typography
              sx={{
                ...linkSx,
                marginTop: 0
              }}
              marginTop={0}
              marginRight={0.5}
            >  
              {texts('common:footer_logo_signing', {
                year: moment().year()
              })}
            </Typography>
            <Link
              href={process.env.REACT_APP_APPFINITY_URL!}
              sx={linkSx}
              underline={'always'}
              color={'#000000'}
            >
              {texts('common:appfinity')}
            </Link>
          </Grid>
          <Grid 
            container item
            flexDirection={'row'}
            justifyContent={'flex-end'}
            xs={12}
            sm={6}
            order={{ xs: 1, sm: 2 }}
          >
            <Link
              marginRight={3}
              href={process.env.REACT_APP_PRIVACY_POLICY_URL}
              sx={linkSx}
              underline={'none'}
              color={'black'}
            >
              {texts('common:privacy_policy')}
            </Link>
            <Link
              href={process.env.REACT_APP_REFUND_POLICY_URL}
              marginRight={3}
              sx={linkSx}
              underline={'none'}
              color={'black'}
            >
              {texts('common:refund_policy')}
            </Link>
            <Link
              href={process.env.REACT_APP_TERMS_URL}
              marginRight={3}
              sx={linkSx}
              underline={'none'}
              color={'black'}
            >
              {texts('common:terms')}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Footer