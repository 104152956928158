export enum Codes {
  AUTH = 100,
  DATA = 200,
  INTERNAL = 999
}

export enum AuthSubcodes {
  INVALID_CREDENTIALS = 100,
  UNAUTHORIZED = 102,
  USER_INACTIVE = 103,
  ACCOUNT_REMOVED = 104,
  INVALID_CODE = 105,
  INCORRECT_PASSWORD = 106,
  SAME_PASSWORD_AS_PREVIOUS_ONE = 107,
  CODE_ALREADY_USED = 108,
  USER_NOT_SUBSCRIBED = 111,
  SUBSCRIPTION_PAST_DUE = 112,
  EMAIL_NOT_VERIFIED = 113,
  COOKIE_EXPIRED = 114,
  ONE_TIME_COOKIE_EXPIRED = 115
}

export enum DataSubcodes {
  BAD_REQUEST = 203,
  EMAIL_TAKEN = 205,
  COMPANY_NOT_FOUND = 212
}

export enum InternalSubcodes {
  FORCED_LOGOUT = 100
}