import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import common from './translations/common.json'
import auth from './translations/auth.json'
import validations from './translations/validations.json'
import errors from './translations/errors.json'
import successes from './translations/successes.json'
import companies from './translations/companies.json'
import accounts from './translations/accounts.json'
import pageTitles from './translations/pageTitles.json'

const resources = {
  pl: {
    common,
    auth,
    validations,
    errors,
    successes,
    companies,
    accounts,
    pageTitles
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'pl',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  }
})

export default i18n