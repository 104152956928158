import SubscribeNow from './SubscribeNow'
import { useTranslation } from 'react-i18next'
import { Dialog, useTheme } from '@mui/material'
import useSubscribe from '../../hooks/stripe/useSubscribe'
import CloseButton from '../customMui/CloseButton'

type Props = {
  open: boolean
  onClose: () => void
}

function SubscribeNowDialog(props: Props) {
  const texts = useTranslation().t
  const theme = useTheme()
  const mutation = useSubscribe()

  function handleClick() {
    mutation.mutate({
      priceId: process.env.REACT_APP_PRODUCT_PRICE_ID!
    })
  }

  return (
    <>
      
      <Dialog
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          sx: {
            backdropFilter: 'blur(5px)',
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
              padding: '50px',
              width: '350px',
              borderRadius: '50px',
            },
            [theme.breakpoints.down('md')]: {
              padding: '30px',
              width: '300px',
              borderRadius: '40px',
            },
            [theme.breakpoints.down('sm')]: {
              padding: '20px',
              width: '250px',
              borderRadius: '30px'
            },
            boxShadow: 4
          }
        }}
      >
        <CloseButton
          onClick={props.onClose}
          sx={{ 
            alignSelf: 'flex-end',
            height: '24px',
            width: '24px'
          }}
          iconSx={{
            height: '16px'
          }}
        />
        <SubscribeNow
          title={texts('auth:subscribe_title')}
          subtitle={texts('companies:subscribe_now_dialog_subtitle')}
          onClick={handleClick}
          displayedPrice={texts('auth:subscribe_display_price')}
          isLoading={mutation.isLoading}
        />
      </Dialog>
    </>
  )
}

export default SubscribeNowDialog