import { LoadingButton } from '@mui/lab'
import { Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  title: string
  subtitle: string
  onClick: () => void
  isLoading: boolean
  displayedPrice: string
  bottomSection?: JSX.Element
}

function SubscribeNow(props: Props) {
  const theme = useTheme()
  const texts = useTranslation().t

  return (
    <Grid
      gap={{ xs: 2, sm: 3, md: 4 }}
      container
      direction={'column'}
      alignItems={'center'}
      width={'100%'}
    >
      <Typography variant={'subtitle1'}>{props.title}</Typography>
      <Typography variant={'subtitle2'} textAlign={{ xs: 'center', sm: 'left' }}>{props.subtitle}</Typography>
      <Grid 
        item 
        container
        direction={'column'}
        alignItems={'center'}
      >
        <Typography 
          sx={{
            fontWeight: '700',
            [theme.breakpoints.up('md')]: {
              fontSize: '40px',
            },
            [theme.breakpoints.down('md')]: {
              fontSize: '32px',
            },
            fontFamily: 'Roboto Bold'
          }}
        >
          {props.displayedPrice}
        </Typography>
        <Typography 
          fontSize={{ xs: '12px', md: '16px' }}
          fontWeight={500}
          fontFamily={'Roboto'}
          sx={{
            opacity: '70%'
          }}
        >
          {texts('auth:subscribe_display_anex')}
        </Typography>
      </Grid>

      <LoadingButton
        onClick={props.onClick}
        variant={'contained'}
        loading={props.isLoading}
        sx={{
          boxShadow: 8,
          textTransform: 'none',
          fontFamily: 'Catamaran Roman Bold',
          [theme.breakpoints.up('md')]: {
            borderRadius: '50px',
            height: '50px',
            width: '200px',
            fontSize: '22px'
          },
          [theme.breakpoints.up('sm')]: {
            borderRadius: '50px',
            height: '50px',
            width: '200px',
            fontSize: '18px'
          },
          [theme.breakpoints.down('sm')]: {
            borderRadius: '20px',
            height: '40px',
            width: '200px',
            fontSize: '16px'
          }
        }}
      >
        {texts('auth:subscribe')}
      </LoadingButton>

      {props.bottomSection != null && props.bottomSection}
    </Grid>
  )
}

export default SubscribeNow