import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthDto } from '../api/auth/types'
import { setUserData, clearUserCache, getAccessStatus, setAccessStatus, getUserUuid } from '../services/storage/StorageServices'

export enum LoginState {
  LOGGED,
  LOGGED_OUT,
  FORCED_LOGGED_OUT
}

export enum AccessStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PAST_DUE = 'past_due'
}

export enum RedirectState {
  UNDEFINED,
  RESUBSCRIBE,
  SUBSCRIBE
}


const defaultState = {
  loginState: getUserUuid() ? LoginState.LOGGED : LoginState.LOGGED_OUT,
  accessStatus: getAccessStatus(),
  redirectState: RedirectState.UNDEFINED
}

const slice = createSlice({
  name: 'storage',
  initialState: defaultState,
  reducers: {
    logIn: (state, action: PayloadAction<AuthDto>) => {
      setUserData(action.payload)
      state.loginState = LoginState.LOGGED
      state.accessStatus = action.payload.accessStatus
    },
    logOut: state => {
      clearUserCache()
      state.loginState = LoginState.LOGGED_OUT
      state.accessStatus = AccessStatus.INACTIVE
    },
    forceLogOut: state => {
      clearUserCache()
      state.loginState = LoginState.FORCED_LOGGED_OUT
      state.accessStatus = AccessStatus.INACTIVE
    },
    updateAccessStatus: (state, action: PayloadAction<AccessStatus>) => {
      state.accessStatus = action.payload
      setAccessStatus(action.payload)
    },
    updateRedirectState: (state, action: PayloadAction<RedirectState>) => {
      state.redirectState = action.payload
    }
  }
})

export const {
  logIn,
  logOut,
  forceLogOut,
  updateAccessStatus,
  updateRedirectState,
} = slice.actions

export default slice.reducer