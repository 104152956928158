import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './i18'
import App, { ROUTER_BASE_NAME } from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { QueryClient, QueryClientProvider } from 'react-query'
import ReactGA from 'react-ga4'
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import ScrollToTop from './components/common/ScrollToTop'

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID!)

Sentry.init({
  dsn: "https://d3ab4747869a4cf3831ecd23c7f25cfd@o4504038321225728.ingest.sentry.io/4504038327451648",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Number.parseInt(process.env.REACT_APP_STALE_TIME_MS!)
    },
  },
})

const RouteAdapter = ({ children }: any) => {
  const navigate = useNavigate()
  const location = useLocation()

  const adaptedHistory = React.useMemo(() => ({
      replace(location: any) {
        navigate(location, { replace: true, state: location.state })
      },
      push(location: any) {
        navigate(location, { replace: false, state: location.state })
      },
    }),
    [navigate]
  )
  return children({ history: adaptedHistory, location });
}

root.render(
  <React.StrictMode>
    <BrowserRouter basename={ROUTER_BASE_NAME}>
      <QueryParamProvider ReactRouterRoute={RouteAdapter}>
        <QueryClientProvider client={queryClient}>
          <ScrollToTop />
          <App/>
        </QueryClientProvider>
      </QueryParamProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
