import { Box, Grid, Pagination, Typography, useTheme } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CompanyTileDto } from '../../../api/data/types'
import store from '../../../redux/store'
import CompanyTile from './CompanyTile'
import EmptyListPLaceHolder from './EmptyListPlaceholder'
import { AccessStatus } from '../../../redux/storageToolkit'
import { useWindowWidth } from '@react-hook/window-size'

type Props = {
  searched: boolean
  onLockedTileClick: () => void
  directToUrl: (company: CompanyTileDto) => string
  onPageChange: (event: React.ChangeEvent<unknown>, value: number) => void
  currentPage: number
  maxPages: number
  companies: CompanyTileDto[]
}

const companiesPerPage = Number.parseInt(process.env.REACT_APP_COMPANIES_PER_PAGE!)

function CompanyListBottomSection(props: Props) {
  const theme = useTheme()
  const texts = useTranslation().t
  const onlyWidth = useWindowWidth()

  const tiles = useMemo(() => {
    const tiles = props.companies.map(company => (
      <CompanyTile
        key={company.uuid}
        onLockedTileClick={props.onLockedTileClick}
        directToUrl={props.directToUrl}
        company={company}
      />
    ))

    if (store.getState().storage.accessStatus !== AccessStatus.ACTIVE) {
      for(let i = tiles.length; i < companiesPerPage; i++) {
        tiles.push(
          <CompanyTile
            key={i}
            onLockedTileClick={props.onLockedTileClick}
            directToUrl={() => ''}
          />
        )
      }
    }

    return tiles
  }, [props.companies, props.onLockedTileClick, props.directToUrl])

  return (
    <Box
      width={'100%'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      marginTop={{ xs: '20px', sm: '30px', md: '48px' }}
      sx={{
        backgroundColor: '#F9F9FF',
      }}
    >
      <Grid
        container
        direction={'column'}
        paddingTop={{ xs: '20px', sm: '15px', md: '30px' }}
        paddingBottom={{ xs: '30px', md: '75px' }}
        gap={{ xs: '20px', sm: '15px', md: '30px' }}
        maxWidth={'1300px'}
        paddingLeft={'24px'}
        paddingRight={'24px'}
      >
        {props.searched
          ? (
            <Typography
            fontSize={{ xs: '18px', sm: '24px', md: '36px'}}
            display={'inline'}
            fontFamily={'Catamaran Roman Bold'}
            >
                {texts('companies:search_results')}
              </Typography>
            )
          : (
            <Box>
                <Typography
                  fontSize={{ xs: '18px', sm: '24px', md: '36px'}}
                  display={'inline'}
                  fontFamily={'Catamaran Roman Bold'}
                  >
                  {texts('companies:list_title')}
                </Typography>
                <Typography
                  fontSize={{ xs: '18px', sm: '24px', md: '36px'}}
                  display={'inline'}
                  fontFamily={'Catamaran Roman Bold'}
                  color={theme.palette.primary.main}
                  >
                  {texts('companies:list_title_2')}
                  </Typography>
                <Typography
                  fontSize={{ xs: '18px', sm: '24px', md: '36px'}}
                  display={'inline'}
                  fontFamily={'Catamaran Roman Bold'}
                >
                  {texts('common:below')}
                </Typography>
              </Box>
            )
          }
        
        {props.companies.length > 0 
          ? ( <>
                {tiles}
                <Pagination
                  siblingCount={onlyWidth < 600 ? 0 : 1}
                  count={props.maxPages}
                  page={props.currentPage}
                  color={'primary'}
                  onChange={props.onPageChange}
                  sx={{
                    alignSelf: 'center',
                    '.MuiButtonBase-root': {
                      ':hover': {
                        backgroundColor: theme.palette.secondary.main
                      }
                    }
                  }}
                  />
              </>
            ) 
            : (<EmptyListPLaceHolder />)
          }

      </Grid>
    </Box>
  )
}

export default CompanyListBottomSection