import { ThemeProvider } from '@mui/material'
import './App.css'
import MainTheme from './components/themes/MainTheme'
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import SignIn from './pages/auth/SignIn'
import SignUp from './pages/auth/SignUp'
import { useEffect, useRef } from 'react'
import ResetPasswordRequest from './pages/auth/ResetPasswordRequest'
import ResetPasswordFinish from './pages/auth/ResetPasswordFinish'
import ResetPasswordVerify from './pages/auth/ResetPasswordVerify'
import SignUpActivation from './pages/auth/SignUpActivation'
import { forceLogOut, LoginState, RedirectState, updateRedirectState } from './redux/storageToolkit'
import store from './redux/store'
import toast, { Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import NotFoundPage from './pages/common/NotFoundPage'
import Dashboard from './pages/common/Dashboard'
import { useWindowWidth } from '@react-hook/window-size'
import { getUserUuid } from './services/storage/StorageServices'

export const ROUTER_BASE_NAME = '/'

function App() {
  const navigate = useNavigate()
  const texts = useTranslation().t
  const onlyWidth = useWindowWidth({ wait: 50 })
  const loginStateRef = useRef<LoginState | null>(null)

  useEffect(() => {
    console.log(process.env.REACT_APP_VERSION)
  }, [])

  const handleLoginStateChange = () => {
    const previousValue = loginStateRef.current
    loginStateRef.current = store.getState().storage.loginState

    if (previousValue === loginStateRef.current) { //stan dla nas sie nie zmienił
      return
    }

    switch (store.getState().storage.loginState) {
      case LoginState.FORCED_LOGGED_OUT:
        toast.success(texts('successes:automatically_logged_out'))
        navigate('/')
        break
      case LoginState.LOGGED_OUT:
        toast.success(texts('successes:logout'))
        navigate('/')
        break
    }
  }

  const handleAccessStatusChange = () => {
    switch (store.getState().storage.redirectState) {
      case RedirectState.SUBSCRIBE:
        navigate('/', { replace: true })
        store.dispatch(updateRedirectState(RedirectState.UNDEFINED))
        break
      case RedirectState.RESUBSCRIBE:
        navigate('/', { replace: true })
        store.dispatch(updateRedirectState(RedirectState.UNDEFINED))
        break
    }
  }

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      handleLoginStateChange()
      handleAccessStatusChange()
    })

    const handleCrossTabLogout = () => {
      if (store.getState().storage.loginState === LoginState.LOGGED && !getUserUuid()) {
        store.dispatch(forceLogOut())
      }
    }
    window.addEventListener('storage', handleCrossTabLogout) 

    return () => {
      window.removeEventListener('storage', handleCrossTabLogout)
      unsubscribe()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loggedRoutes = () => {
    return <Route path='*' element={<Dashboard />}/> 
  } 

  const unLoggedRoutes = () => {
    return (
      <>
        <Route path='/' element={<Outlet/>}>
          <Route index element={<SignIn/>}/>  
          <Route path='*' element={<SignIn />} />   
          <Route path='register' element={<SignUp/>}/>
          <Route path='activation' element={<SignUpActivation/>}/>
          <Route path='password/reset' element={<Outlet/>}>
            <Route index element={<ResetPasswordRequest/>}/>
            <Route path='finish' element={<ResetPasswordFinish/>}/>
            <Route path='verify' element={<ResetPasswordVerify/>}/>
          </Route>
        </Route>
        <Route path='*' element={<NotFoundPage/>}/>
      </>
    )
  }

  return (
    <ThemeProvider theme={MainTheme}>
      <Toaster
        position={ onlyWidth > 600 ? 'top-right' : 'top-center' }
        containerStyle={{
          fontFamily:'Roboto',
          top:'5rem'
        }}
        toastOptions={{
          success: {duration: 4000},
          error: {duration: 4000}
        }}
      />
      <Routes>
        {
          store.getState().storage.loginState === LoginState.LOGGED
            ? loggedRoutes()
            : unLoggedRoutes()
        }
      </Routes>
    </ThemeProvider>
  )
}
export default App
