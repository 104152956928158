import { LoginDto } from '../../api/auth/types'
import AuthApi from '../../api/auth/AuthApi'
import { useMutation } from 'react-query'
import store from '../../redux/store'
import { logIn } from '../../redux/storageToolkit'
import { useTranslation } from 'react-i18next'
import { Codes, AuthSubcodes } from '../../services/errors/consts'
import { AxiosError } from 'axios'
import { HttpError } from '../../types/common'
import { useNavigate } from 'react-router-dom'
import { showErrorToast } from '../../utils/error'

const authApi = AuthApi.getInstance()

export default function useLogIn() {
  const texts = useTranslation().t
  const navigate = useNavigate()

  const mutation = useMutation((data: LoginDto) => authApi.login(data), {
    onSuccess: (data) => {
      store.dispatch(logIn(data))
    },
    onError: (error: AxiosError<HttpError>, variables: LoginDto) => {
      const isEmailNotVerified = error.response?.data?.code === Codes.AUTH && error.response?.data?.subcode === AuthSubcodes.EMAIL_NOT_VERIFIED
      
      if (isEmailNotVerified) {
        navigate('/activation', {
          state: {
            email: variables.login
          }
        })
      } else {
        showErrorToast(error, texts)
      }
    },
  })
  return mutation
}