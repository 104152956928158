import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import useGetCurrentAccessState from '../../hooks/stripe/useGetCurrentAccessState'
import Account from '../account/Account'
import Settings from '../account/Settings'
import Subscribe from '../auth/Subscribe'
import NotFoundPage from './NotFoundPage'
import CompanyReport from '../company/CompanyReport'
import Home from '../company/Home'
import LoadingPage from './LoadingPage'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

function Dashboard() {
  const texts = useTranslation().t
  const accessState = useGetCurrentAccessState()
  const [delayed, setDelayed] = useState<boolean>(false)
  setTimeout(() => setDelayed(true), 2500)

  return (
    <>
      <Helmet>
        <title>{texts('pageTitles:default')}</title>
      </Helmet>
      {accessState.data && delayed
        ? <Routes>
            <Route path='/' element={<Outlet />}>
              <Route index element={<Home />} />
              <Route path='companies/:companyUuid' element={<CompanyReport />} />   
              <Route path='account' element={<Outlet />} >
                <Route index element={<Account />} />
                <Route path='settings' element={<Settings />} />
              </Route>
              <Route path='subscribe' element={<Subscribe />}/>  
            </Route>
            <Route index element={<Navigate to={'/'}/>}/>  
            <Route path='register' element={<Navigate to={'/'}/>}/>
            <Route path='activation' element={<Navigate to={'/'}/>}/>
            <Route path='password/reset' element={<Navigate to={'/'}/>}>
              <Route index element={<Navigate to={'/'}/>}/>
              <Route path='finish' element={<Navigate to={'/'}/>}/>
              <Route path='verify' element={<Navigate to={'/'}/>}/>
            </Route>
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        : <LoadingPage/>
      }
    </>
  )
}

export default Dashboard