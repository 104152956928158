import { Box, Grid } from '@mui/material'
import { useState, useEffect } from 'react';
import { Transition } from 'react-transition-group'
import LogoSrc from '../../assets/images/Logo.svg'
import MainLayout from '../../components/layouts/MainLayout'

function mapOpacity(transition: string) {
  switch(transition) {
    case 'entered':
    case 'entering':
      return 1
    case 'exiting':
    case 'exited': 
      return 0.2
    default:
      return 0
  }
}

function LoadingPage() {
  const [transition, setTransition] = useState<boolean>(true)
  useEffect(() => {
    setTimeout(() => setTransition(!transition), 500)
  }, [transition])

  return (
    <MainLayout>
      <Grid
        container
        width={'100%'}
        height={'calc(100vh - 230px)'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Transition in={transition} timeout={0} >
          {
            (transition) => (
              <Box
                component={'img'}
                src={LogoSrc}
                width={{ xs: '184px', sm: '240px', md: '320px' }}
                sx={{
                  opacity: mapOpacity(transition),
                  transition: 'opacity 500ms ease-in'
                }}
              />      
            ) 
          }
        </Transition>
      </Grid>
    </MainLayout>
    
  )
}

export default LoadingPage