import { AppBar, Box, SxProps, Toolbar, useTheme } from '@mui/material'
import imageSrc from '../../assets/images/Logo.svg'
import AccountNav from '../customMui/AccountNav'
import Footer from './Footer'
import store from '../../redux/store'
import { LoginState } from '../../redux/storageToolkit'
import { useNavigate } from 'react-router-dom'

export type Props = {
  middleElement?: JSX.Element
  children?: JSX.Element
  hideLogo?: boolean
  hideAccountNav?: boolean
  sx?: SxProps
}

function MainLayout(props: Props) {
  const theme = useTheme()
  const navigate = useNavigate()
  const loggedIn = store.getState().storage.loginState === LoginState.LOGGED
  
  const handleLogoClick = loggedIn
    ? () => navigate('/', {
      replace: true
    })
    : () => window.location.replace(process.env.REACT_APP_NEXT_LANDING_PAGE_URL!)


  return (
    <Box
      //position={'absolute'}
      margin={0}
      width={'100%'}
      minHeight={'100vh'}
      sx={props.sx}
      display={'flex'}
      flexDirection={'column'}
   >
      <AppBar 
        component={'nav'} 
        position={'static'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '0 24px',
          '.MuiToolbar-root': {
            padding: '0'
          }
        }}
      >
        <Toolbar
          sx={{
            width: '100%',
            maxWidth: '1252px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            [theme.breakpoints.up('md')]: {
              height: '76px',
              minHeight: '76px'
            },
            [theme.breakpoints.down('md')]: {
              height: '60px',
              minHeight: '60px'
            },
            [theme.breakpoints.down('sm')]: {
              height: '48px',
              minHeight: '48px'
            }
          }}
        >
          {!props.hideLogo && 
            <Box
              onClick={handleLogoClick}
              component={'img'}
              src={imageSrc} 
              alt={'Breaking Insights logo'}
              sx={{
                ':hover': {
                  cursor: 'pointer'
                },
                [theme.breakpoints.up('md')]: {
                  height: '32px',
                  width: '216px'
                },
                [theme.breakpoints.up('sm')]: {
                  height: '24px',
                  width: '162px'
                },
                [theme.breakpoints.down('sm')]: {
                  height: '20px',
                  width: '136px'
                }
              }}
            />
          }
          {props.middleElement != null && props.middleElement}
          {!props.hideAccountNav && loggedIn && <AccountNav />}
        </Toolbar>
      </AppBar>
        <Box
          height={'fit-content'}
        >
          {props.children}
        </Box>
        <Box flexGrow={1}/>
      <Footer 
        sx={{
          bottom: 0,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      />
    </Box>
  )
}

export default MainLayout