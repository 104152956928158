import { AxiosError } from 'axios'
import { useQuery, useQueryClient } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { CompanyHintDto } from '../../api/data/types'
import { getUserUuid } from '../../services/storage/StorageServices'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

type Options = {
  onError?: (error: AxiosError) => void 
  onSuccess?: (result: CompanyHintDto[]) => void
  enabled?: boolean,
  staleTime?: number,
  cacheTime?: number
}

export default function useHintCompanies(value: string, previousValue: string, options?: Options) {
  const queryClient = useQueryClient()
  const query = useQuery([queryNames.companiesTile, getUserUuid(), value], () => {
    return value ? dataApi.hintCompanies(value) : [] //prevents request on '' value when reset
  }, {
    ...options,
    initialData: queryClient.getQueryData([queryNames.companiesTile, getUserUuid(), previousValue])
  })

  return query
}