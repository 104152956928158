import { useTranslation } from 'react-i18next'
import { Dialog, Grid, Typography, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import useViewBillings from '../../hooks/stripe/useViewBillings'
import CloseButton from '../customMui/CloseButton'

type Props = {
  open: boolean
  onClose: () => void
}

function SubscriptionRenewalDialog(props: Props) {
  const texts = useTranslation().t
  const theme = useTheme()
  const mutation = useViewBillings()

  function handleClick() {
    mutation.mutate()
  }

  return (
    <Dialog 
      open={props.open}
      onClose={props.onClose}
      PaperProps={{
        sx: {
          display: 'flex',
          justifyContent: 'center',
          [theme.breakpoints.up('md')]: {
            padding: '50px',
            width: '350px',
            borderRadius: '50px',
          },
          [theme.breakpoints.down('md')]: {
            padding: '30px',
            width: '300px',
            borderRadius: '40px',
          },
          [theme.breakpoints.down('sm')]: {
            padding: '20px',
            width: '250px',
            borderRadius: '30px'
          },
          boxShadow: 4
        }
      }}
    >
      <CloseButton 
        onClick={props.onClose}
        sx={{ 
          alignSelf: 'flex-end',
          height: '24px',
          width: '24px'
        }}
        iconSx={{
          height: '16px'
        }}
      />
      <Grid
        gap={{ xs: 2, sm: 3, md: 4 }}
        container
        direction={'column'}
        alignItems={'center'}
        width={'100%'}
      >
        <Typography variant={'subtitle1'}>{texts('companies:renew_subscription_title')}</Typography>
        <Typography variant={'subtitle2'}>{texts('companies:renew_subscription_subtitle')}</Typography>

        <LoadingButton
          onClick={handleClick}
          variant={'contained'}
          loading={mutation.isLoading}
          sx={{
            boxShadow: 8,
            textTransform: 'none',
            fontFamily: 'Catamaran Roman Bold',
            [theme.breakpoints.up('md')]: {
              borderRadius: '50px',
              height: '50px',
              width: '200px',
              fontSize: '22px'
            },
            [theme.breakpoints.up('sm')]: {
              borderRadius: '50px',
              height: '50px',
              width: '200px',
              fontSize: '18px'
            },
            [theme.breakpoints.down('sm')]: {
              borderRadius: '20px',
              height: '40px',
              width: '200px',
              fontSize: '16px'
            }
          }}
        >
          {texts('companies:renew_subscription_button')}
        </LoadingButton>
      </Grid>
    </Dialog>
  )
}

export default SubscriptionRenewalDialog