import { TextField as MuiTextField, TextFieldProps, InputAdornment, IconButton, SxProps } from '@mui/material'
import { useField } from 'formik'
import { VisibilityOff, Visibility } from '@mui/icons-material'
import * as React from 'react'

type Props = {
  name: string
  label?: string
  disabled?: boolean
  placeholder?: string
  sx?: SxProps
  InputProps?: {
    endAdornment?: React.ReactNode
    startAdornment?: React.ReactNode
  }
}

const PasswordField = (props: Props) => {
  const [field, meta] = useField(props.name)
  const [showPassword, setShowPassword] = React.useState<boolean>(false)

  const configTextField: TextFieldProps = {
    ...field,
    ...props,
    fullWidth: true,
    variant: 'outlined'
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  if (meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  }

  return (
    <MuiTextField
      {...configTextField}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: 
        <InputAdornment position='end'>
          <IconButton
            aria-label='toggle password visibility'
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge='end'
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>,
      }}
    /> 
  )
}

export default PasswordField
